import { Typography, Box } from "@mui/material";
import { ReactComponent as Positive } from "../assets/Positive.svg";
import { ReactComponent as Neutral } from "../assets/Neutral.svg";
import { ReactComponent as Negative } from "../assets/Negative.svg";
import { ReactComponent as Disabled } from "../assets/Disabled.svg";

import { useUser } from "../helpers/UserContext";

const borderRadius = "10px";
const negativeColor = "#640012";
const negativeText = "NEGATIVE";
const neutralColor = "#AD6000";
const neutralText = "NEUTRAL";
const positiveColor = "#00856F";
const positiveColorBright = "#6FBD44";
const positiveText = "POSITIVE";
const disabledColor = "#757575";

interface ScoreModalProps {
  title: string;
  score: number;
}


const SVGScoreModal: React.FC<ScoreModalProps> = ({ title, score }) => {

  const { subscriptionType, subscriptionId } = useUser()


  const getScoreType = (score: number) => {

    if (!subscriptionId || subscriptionId === "none" || !subscriptionType || subscriptionType === "none" || subscriptionType === "basic") {
      return -1
    }


    if (score >= 0 && score < 4) return 0;
    else if (score >= 4 && score <= 7) return 1;
    return 2;
  };



  const getBoxBackgroundColor = (score: number) => {

    if (!subscriptionId || subscriptionId === "none" || !subscriptionType || subscriptionType === "none" || subscriptionType === "basic") {
      return disabledColor
    }
    let scoreType = getScoreType(score);
    if (scoreType == 2) return positiveColor;
    else if (scoreType == 1) return neutralColor
    return negativeColor;
  };


  const borderGradient =
    "transparent linear-gradient(290deg, #6FBD44 0%, #E5A233 80%, #B00020 100%) 0% 0% no-repeat padding-box";

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderRadius={borderRadius}
      borderTop={10}
      border={`5px solid ${borderGradient}`}
      padding={2}
      paddingBottom={5}
      sx={{
        backgroundColor: getBoxBackgroundColor(score),
        boxShadow: "0px 3px 8px #31313140",
        height: "350px",
        borderColor: "transparent",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "10%",
          }}
        >
          <Typography
            fontSize={"30px"}
            fontStyle={"Helvetica"}
            gutterBottom
            align="center"
            color={'#FFFFFF'}
          // sx={{ color: getTopTextColor(score) }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "10%",
          }}
        >
          <Typography
            fontSize={"20px"}
            fontStyle={"Helvetica"}
            gutterBottom
            align="center"
            color={'#FFFFFF'}
            sx={{
              // color: getSubTextColor(score),
              marginTop: "20px",
            }}
          >
            {getScoreType(score) == 2
              ? positiveText
              : getScoreType(score) == 1
                ? neutralText
                : negativeText}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "250px",
              paddingBottom: "min(100%, 250px)",
              position: "relative",
            }}
          >
            {getScoreType(score) == 2 ? (
              <Positive
                style={{
                  position: "absolute",
                  transform: "translate(-14%, -28%)",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  // backgroundColor:'red',
                  height: "400px",
                  width: "350px",
                }}
              />
            ) : getScoreType(score) == 1 ? (
              <Neutral
                style={{
                  position: "absolute",
                  transform: "translate(-14%, -28%)",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  // backgroundColor:'red',
                  height: "400px",
                  width: "350px",
                }}
              />
            ) : getScoreType(score) == 0 ? (
              <Negative
                style={{
                  position: "absolute",
                  transform: "translate(-14%, -28%)",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  // backgroundColor:'red',
                  height: "400px",
                  width: "350px",
                }}
              />
            ) : (
              <Disabled
                style={{
                  position: "absolute",
                  transform: "translate(-14%, -28%)",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  // backgroundColor:'red',
                  height: "400px",
                  width: "350px",
                }}
              />)}
            {/* Text Box */}
            <Box
              textAlign="center"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -55%)",
              }}
            >
              <Typography
                color={'#FFFFFF'}

                // color={getSubTextColor(score)}
                sx={{
                  fontSize: "6.25rem",
                  fontWeight: "bold",
                }}
              >
                {score.toFixed(1)}
              </Typography>

              <Typography
                // color={getSubTextColor(score)}
                color={'#FFFFFF'}
                sx={{ fontSize: "1.875rem", marginTop: "-40px" }}
              >
                Out of 10
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SVGScoreModal;
