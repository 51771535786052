import React, { useState } from "react";
import { Button, CircularProgress, SvgIcon, Typography } from "@mui/material";
import { doUnLinkSlack } from "../helpers/api";
import { useUser } from "../helpers/UserContext";
import { toast } from "react-toastify";
import { primary } from "../theme/colors";
import { Height } from "@mui/icons-material";

const SlackIcon: React.FC = (props) => (
  // <SvgIcon {...props}>
  //     <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9z" fill="#e01e5a"></path>
  // </SvgIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z"
    />
  </svg>
);

interface SlackLoginButtonProps {
  buttonText: string;
  action: string;
}

const SlackLoginButton: React.FC<SlackLoginButtonProps> = ({
  buttonText,
  action,
}) => {
  const { authToken, updateProfile } = useUser();
  const [loader, setLoader] = useState(false);

  const handleClick = async () => {
    if (loader) {
      return
    }
    setLoader(true)
    if (action === "link") {
      window.location.href = process.env.REACT_APP_SLACK_AUTH_URL + window.location.origin + "/profile" || "";
    } else if (action === "unlink") {
      console.log("Unlink is Pressed");
      const response = await doUnLinkSlack(authToken!);
      if (response.error) {
        console.error("response", response);
        //   toast.error("Linking Failed")
      } else {
        console.log("response", response?.data);
        localStorage.removeItem("slack_team_name");
        updateProfile([{ Name: "custom:slackTeamName", Value: "none" }]);
        toast.success(response?.data?.message);
        setLoader(false)
      }
    }
  };
  return (
    <Button
      // href={process.env.REACT_APP_SLACK_AUTH_URL}
      onClick={handleClick}
      variant="contained"
      color="primary"
      startIcon={!loader && <SlackIcon />}
      sx={{
        backgroundColor: "#4A154B",
        "&:hover": { backgroundColor: "#3A0D3B" },
        justifyContent: "center",
        // width: "230px",
        Height: "50px",
      }}
    >
      {loader ? (
        <CircularProgress size={"20px"} style={{ color: primary.white, padding: 4 }} />
      ) : (
        <Typography style={{ marginTop: "5px" }}>{buttonText}</Typography>
      )}
    </Button>
  );
};

export default SlackLoginButton;
