import EngagementDropdown from './EngagementDropdown';
import TimeToggleGroup from './TimeToggleGroup';
import {
  Grid,
  Box,
  Typography,
  InputAdornment,
  TextField,
  Switch,
  Divider,
  styled,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import ReactWordcloud from 'react-wordcloud';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import { primary } from '../theme/colors';
import { words } from '../constants/constants';
import CATable from '../theme/conversationAnalysisTable';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { doLoadConversationBreakdown } from '../helpers/api';
import { useUser } from '../helpers/UserContext';
import { useTourContext } from '../helpers/TourContext';
import RestrictedAreaComponent from './RestrictedAreaComponent';
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.2),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
const timePeriodOptions = [
  {
    name: 'Past Week',
    value: 'week',
  },
  {
    name: 'Past Month',
    value: 'month',
  },
  {
    name: 'Past 3 Months',
    value: 'quarter',
  },
];

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: primary.logoColor,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
}));
const loaderStyle = {
  border: '8px solid #f3f3f3',
  borderTop: '8px solid #3498db',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  animation: 'spin 2s linear infinite',
};
const keyframesStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

const ConversationBreakdown: React.FC = () => {
  const { authToken } = useUser();
  const [loading, setLoading] = useState(false);
  const [sentence, setSentence] = useState([]);
  const [trendingWords, setTrendingWords] = useState();
  const [channel, setChannel] = useState<string>('all');
  const [positiveOnly, setPositiveOnly] = useState(false);
  const [neutralOnly, setNeutralOnly] = useState(false);
  const [negativeOnly, setNegativeOnly] = useState(false);
  const [filteredComments, setFilteredComments] = useState(sentence);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const {
    updateTourState,
    tourState: { tourActive },
  } = useTourContext();

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the threshold as needed
      console.log('ScreenSize', isSmallScreen, window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [alignment, setAlignment] = useState<string>('week');

  console.log('Hello', sentence, filteredComments);

  useEffect(() => {
    console.warn('CONVEBREAKDWON LOADING', loading, tourActive);
    if (tourActive) {
      updateTourState({ run: !loading });
    } else {
      updateTourState({ run: false });
    }
  }, [loading, tourActive]);

  useEffect(() => {
    // Filter comments based on switch states
    const filtered = sentence.filter((object: any) => {
      console.log('useEffect', object);

      if (positiveOnly && object.sentiment === 'positive') return true;
      if (neutralOnly && object.sentiment === 'neutral') return true;
      if (negativeOnly && object.sentiment === 'negative') return true;
      if (!positiveOnly && !neutralOnly && !negativeOnly) return true;
      return false;
    });

    const searchFiltered = filtered.filter((comment: any) =>
      comment.sentence.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredComments(searchFiltered);
    // setFilteredComments(filtered);
  }, [positiveOnly, neutralOnly, negativeOnly, searchQuery, sentence]);

  const handleSearch = (input: any) => {
    console.log('Inside Search', input.target.value);
    setSearchQuery(input.target.value);
  };

  const [conversationData, setConversationData] = useState([
    {
      id: 'positive',
      name: 'Positive',
      percentage: 0,
      icon: (
        <SentimentSatisfiedAltIcon
          style={{ fontSize: '4.063rem', color: primary.positive }}
        />
      ),
      mostTyped: null,
    },
    {
      id: 'neutral',
      name: 'Neutral',
      percentage: 0,
      icon: (
        <SentimentNeutralIcon
          style={{ fontSize: '4.063rem', color: primary.neutral }}
        />
      ),
      mostTyped: null,
    },
    {
      id: 'negative',
      name: 'Negative',
      percentage: 0,
      icon: (
        <SentimentVeryDissatisfiedIcon
          style={{ fontSize: '4.063rem', color: primary.negative }}
        />
      ),
      mostTyped: null,
    },
  ]);
  const filtersArray = [
    {
      name: 'Positive Only',
      value: 'positive',
    },
    {
      name: 'Neutral Only',
      value: 'neutral',
    },
    {
      name: 'Negative Only',
      value: 'negative',
    },
  ];

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    console.log('newAlignment', newAlignment);
    setAlignment(newAlignment);
  };

  const handleAlignment = (_event: any, newAlignment: any) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  useEffect(() => {
    if (loading) {
      return;
    }
    setLoading(true);

    doLoadConversationBreakdown(
      authToken!,
      alignment,
      channel !== 'all' ? channel : null
    ).then((response) => {
      if (response.error) {
        console.log(response.error);
        setLoading(false);
      } else {
        console.log(response.data);

        let fetchedData = response.data;
        let maxPositive = 0;
        let maxPositiveText = '';
        let maxNeutral = 0;
        let maxNeutralText = '';
        let maxNegative = 0;
        let maxNegativeText = '';

        fetchedData.words.map((word: any) => {
          if (word.sentiment === 'positive') {
            if (word.value > maxPositive) {
              maxPositive = word.value;
              maxPositiveText = word.text;
            }
          } else if (word.sentiment === 'neutral') {
            if (word.value > maxNeutral) {
              maxNeutral = word.value;
              maxNeutralText = word.text;
            }
          } else if (word.sentiment === 'negative') {
            if (word.value > maxNegative) {
              maxNegative = word.value;
              maxNegativeText = word.text;
            }
          }
        });

        console.warn(
          'Test Sentiments',
          maxPositiveText,
          maxPositive,

          maxNeutralText,
          maxNeutral,

          maxNegativeText,
          maxNegative,
          conversationData,
          fetchedData
        );
        // setMood(fetchedData.mood);
        setSentence(fetchedData.sentence);
        setTrendingWords(fetchedData.words);

        const updatedData = conversationData.map((object, index) => ({
          ...object,
          percentage: fetchedData.mood![object.id],
          mostTyped:
            index === 0
              ? maxPositiveText
              : index === 1
              ? maxNeutralText
              : maxNegativeText,
        }));

        setConversationData(updatedData as any);
        // setChannels("public", response?.data?.channels || []);
        setLoading(false);
      }
    });
  }, [alignment, channel]);

  return (
    <Box p={4}>
      {loading ? (
        <div
          style={{
            position: 'fixed',
            top: 60,
            left: !isSmallScreen ? 114 : 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // White overlay background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Make sure the loader is on top
          }}
        >
          <div style={loaderStyle}></div>
        </div>
      ) : null}
      <Grid container gap={4}>
        {/* Duration Selection Row */}
        <Grid item xs={12} sm={12}>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <EngagementDropdown
                  onChange={(e) => setChannel(e.target.value)}
                  // onChange={(evt) => {
                  //   console.log(evt.target.value);
                  // }}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label='Platform'
                  defaultValue='pw'
                  size='large'
                  sx={{
                    '&.MuiToggleButtonGroup-root': {
                      height: 40,
                      width: { xs: '100%', md: '60%' },
                      borderRadius: 1,
                      backgroundColor: '#F5F5F5',
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                    },
                  }}
                >
                  {timePeriodOptions.map((option, index) => (
                    <>
                      <StyledToggleButtonGroup
                        size='large'
                        value={alignment}
                        exclusive
                        onChange={handleAlignment}
                        aria-label='text alignment'
                        sx={{ width: '100%' }}
                      >
                        <ToggleButton
                          key={option.value}
                          sx={{
                            textTransform: 'unset',
                            fontStyle: 'Helvetica',
                            color: '#5A5A5A',
                            width: '100%',
                            '&.Mui-selected, &.Mui-selected:hover': {
                              boxShadow: '4px 0px 4px #5A5A5A29',
                              color: '#1A1A1A',
                              fontWeight: 'bold',
                              backgroundColor: '#FFFFFF',
                              borderRaius: '5px',
                              width: '100%',
                            },
                          }}
                          value={option.value}
                          selected={alignment === option.value}
                          onChange={(event) =>
                            handleAlignment(event, option.value)
                          }
                        >
                          <Typography fontSize={{ xs: '14px', sm: '16px' }}>
                            {option.name}
                          </Typography>
                        </ToggleButton>
                      </StyledToggleButtonGroup>
                      {alignment === option.value ||
                        (index !== timePeriodOptions.length - 1 && (
                          <Divider
                            flexItem
                            orientation='vertical'
                            sx={{ mx: 0.5, my: 1 }}
                          />
                        ))}
                    </>
                  ))}
                </ToggleButtonGroup>

                {/* <TimeToggleGroup /> */}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid container gap={4}>
          {/* Conversation Mood */}

          <Grid
            id='coversationMoodContainer'
            item
            xs={12}
            sm={12}
            md={8}
            lg={6.5}
            sx={{
              borderRadius: 3,
              boxShadow: '0px 3px 8px #31313140',
              padding: 3,
            }}
          >
            <Grid>
              <Grid
                item
                xs={12}
                sx={{ paddingBottom: '10px' }}
                marginBottom={1}
              >
                <Typography fontSize={'30px'} fontFamily={'Helvetica'}>
                  Conversation Mood
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: { xs: 'column', sm: 'row' },
                  }}
                >
                  <Grid container>
                    {conversationData.map((mood, index) => (
                      <Grid xs={12} sm={4} md={4} lg={4}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexDirection: { xs: 'row', sm: 'column' },
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              marginRight: { sm: 2, xs: 4 },
                            }}
                          >
                            {mood.icon}
                            <Box sx={{ marginLeft: '10px', width: '100px' }}>
                              <Typography
                                fontWeight={'bold'}
                                fontStyle={'Helvetica'}
                                style={{
                                  marginTop: '10px',
                                  fontSize: '2.188rem',
                                }}
                              >
                                {mood.percentage}%
                              </Typography>
                              <Typography
                                fontStyle={'Helvetica'}
                                color={
                                  mood.id === 'positive'
                                    ? primary.positive
                                    : mood.id === 'neutral'
                                    ? primary.neutral
                                    : primary.negative
                                }
                                sx={{
                                  marginTop: { xs: '-18px', sm: '-18px' },
                                  fontSize: '1.563rem',
                                }}
                              >
                                {mood.name}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider
                            style={{
                              color: 'red',
                              marginTop: '20px',
                              marginBottom: '20px',
                            }}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              // marginRight: { sm: 2, xs: 4 },
                            }}
                          >
                            <Box sx={{ marginLeft: '0px' }}>
                              <Typography
                                fontSize={'17px'}
                                color={'#757575'}
                                fontStyle={'Helvetica'}
                              >
                                Most typed
                              </Typography>
                              <Typography
                                fontStyle={'Helvetica'}
                                fontSize={'18px'}
                                fontWeight={'bold'}
                                color={
                                  mood.id === 'positive'
                                    ? primary.positive
                                    : mood.id === 'neutral'
                                    ? primary.neutral
                                    : primary.negative
                                }
                              >
                                {mood.mostTyped || 'No Words'}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        {index !== conversationData.length - 1 && (
                          <Divider
                            sx={{
                              display: { xs: 'block', sm: 'none' },
                            }}
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Trending Words */}
          <Grid
            id='trendingWordsContainer'
            item
            xs={12}
            md={3}
            lg={4.4}
            sx={{
              borderRadius: 3,
              boxShadow: '0px 3px 8px #31313140',
              padding: 3,
            }}
          >
            <Box>
              <Typography fontSize={'30px'} fontFamily={'Helvetica'}>
                Trending Words
              </Typography>
            </Box>
            <Box sx={{ height: '180px' }}>
              <ReactWordcloud
                maxWords={(trendingWords as any)?.length || 25}
                callbacks={{
                  getWordColor: (word: any) => {
                    let sentiment = word.sentiment;

                    return sentiment === 'positive'
                      ? primary.positive
                      : sentiment === 'neutral'
                      ? primary.neutral
                      : primary.negative;
                  },
                }}
                options={{
                  rotationAngles: [0, 0],
                  rotations: 0,
                  padding: 5,
                  enableTooltip: false,
                  fontFamily: 'Helvetica',
                  colors: [primary.positive, primary.neutral, primary.negative],
                  deterministic: false,
                  fontSizes: [15, 60],
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                }}
                words={trendingWords || []}
              />
            </Box>
          </Grid>

          {/* Conversation Analysis */}
          <Grid
            id='conversationAnalysisContainer'
            item
            xs={12}
            md={11.4}
            sx={{
              borderRadius: 3,
              boxShadow: '0px 3px 8px #31313140',
            }}
          >
            <Box sx={{ padding: 3 }}>
              <Typography fontSize={'30px'} fontFamily={'Helvetica'}>
                Conversation Analysis
              </Typography>
            </Box>
            <Grid container>
              <Grid xs={12} sm={12} md={5} lg={5} padding={2}>
                <Typography
                  style={{ fontSize: '16px' }}
                  fontStyle={'Helvetica'}
                >
                  Search {sentence.length} Comments
                </Typography>
                <TextField
                  id='filled-search'
                  onChange={handleSearch}
                  type='Searching'
                  placeholder='Search words, phrases, sentences, etc...'
                  fullWidth
                  variant={'outlined'}
                  sx={{
                    // '&.Mui-focused fieldset': {
                    //   borderColor: 'yellow',
                    // },
                    // '& label.Mui-focused': {
                    //   color: 'white',
                    // },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgba(0,0,0,0.3)',
                      },
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon style={{ color: '#00A0D5' }} />
                        <Typography color='#097BB9' fontStyle={'Helvetica'}>
                          Search
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  size='small'
                />
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{
                  marginLeft: { sm: '30px', xs: '30px' },
                  marginBottom: { xs: '10px', md: '0px' },
                  // backgroundColor:'red'
                }}
                container
                padding={{ md: 0, lg: 2 }}
                alignItems={'center'}
              >
                {filtersArray.map((filter, index) => (
                  <>
                    <Grid xs={4} container marginTop={'20px'}>
                      <Typography
                        fontStyle={'Helvetica'}
                        fontSize={'14px'}
                        style={{ marginTop: '10px', color: primary.logoColor }}
                      >
                        {filter.name}
                      </Typography>
                      <StyledSwitch
                        checked={
                          filter.value === 'positive'
                            ? positiveOnly
                            : filter.value === 'negative'
                            ? negativeOnly
                            : neutralOnly
                        }
                        onChange={() =>
                          filter.value === 'positive'
                            ? setPositiveOnly(!positiveOnly)
                            : filter.value === 'negative'
                            ? setNegativeOnly(!negativeOnly)
                            : setNeutralOnly(!neutralOnly)
                        }
                        // style={{ color: primary.logoColor }}
                      />
                      {index !== filtersArray.length - 1 && (
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '30px',
                            marginTop: '5px',
                            marginLeft: '5px',
                            color: 'yellow',
                          }}
                        />
                      )}
                    </Grid>
                  </>
                ))}
              </Grid>
            </Grid>
            <CATable sentence={filteredComments || sentence} />
          </Grid>
        </Grid>
      </Grid>
      <style>{keyframesStyle}</style>
      {/* <RestrictedAreaComponent /> */}
    </Box>
  );
};

export default ConversationBreakdown;
