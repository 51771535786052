import React from 'react';
import DashboardComponent from '../components/DashboardComponent';
import { Box } from '@mui/system';

const DashboardPage: React.FC = () => {

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        height: '100%',
      }}
    >
      <DashboardComponent />
    </Box>
  );
};

export default DashboardPage;