import React from "react";
import {
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useUser } from "../helpers/UserContext";
import { primary } from "../theme/colors";
import { useNavigate } from "react-router-dom";
import { usePayment } from "../helpers/PaymentContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import { doUnLinkSlack } from "../helpers/api";

const ErrorHeader: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { paymentLink } = usePayment()

  const { subscriptionId, subscriptionType, slackTeamName } = useUser()

  const [loader, setLoader] = React.useState<boolean>(false)
  const { authToken, } = useUser()

  const handleClick = async (action: string = 'link') => {
    if (loader) {
      return
    }
    setLoader(true)
    if (action === "link") {
      window.location.href = process.env.REACT_APP_SLACK_AUTH_URL + window.location.origin + "/profile" || "";
    } else if (action === "unlink") {
      console.log("Unlink is Pressed");
      const response = await doUnLinkSlack(authToken!);
      if (response.error) {
        console.error("response", response);
        //   toast.error("Linking Failed")
      } else {
        console.log("response", response?.data);
        localStorage.removeItem("slack_team_name");
        toast.success(response?.data?.message);
        setLoader(false)
      }
    }
  };


  // if (!subscriptionId || subscriptionId === "none" || !subscriptionType || subscriptionType === "none" || subscriptionType === "basic") {
  //   return (
  //     <Grid container spacing={5} style={{ backgroundColor: primary.negative }}>
  //       <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
  //         <Typography
  //           style={{ padding: 10 }}
  //           textAlign={'center'}
  //           color={primary.white}>
  //           You will only see real data once you subscribe to premium or enterprise plan.
  //           <Button
  //             variant="outlined"
  //             color="primary"
  //             size={'small'}
  //             onClick={() => {
  //               if (paymentLink) window.location.href = paymentLink

  //               else navigate("/profile")
  //             }}
  //             sx={{
  //               color: primary.white,
  //               borderColor: primary.white,
  //               fontWeight: "bold",
  //               textTransform: "none",
  //               marginLeft: "10px",
  //               "&:hover": {
  //                 backgroundColor: primary.white,
  //                 color: primary.negative,
  //               },
  //             }}
  //           >
  //             Subscribe Now
  //           </Button>
  //         </Typography>
  //       </Grid>
  //     </Grid>
  //   );
  // }
  if (!slackTeamName || slackTeamName === "none") {
    return (
      <Grid container spacing={5} style={{ backgroundColor: primary.negative }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
          <Typography
            style={{ padding: 10, fontWeight: 'bold' }}
            textAlign={'center'}
            color={primary.white}>
            <FontAwesomeIcon icon={faExclamationCircle} color='white' size={'sm'} style={{ paddingRight: 10 }} />

            No Slack Connection! To restore your dashboard, <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                handleClick('link')
              }}>connect to a Slack workspace</span>

          </Typography>
        </Grid>
      </Grid>
    );
  }

  return null


};

export default ErrorHeader;
