import React, { useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './CardSectionStyles.css';
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useUser } from '../helpers/UserContext';
import {
  addNewCard,
  cancelSubscription,
  createStripeCustomer,
  initiateSubscription,
} from '../helpers/api';
import { toast } from 'react-toastify';
import { primary } from '../theme/colors';
import { usePayment } from '../helpers/PaymentContext';
import { AddCard, Delete } from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faCcJcb,
  faCcDinersClub,
  faCcStripe,
} from '@fortawesome/free-brands-svg-icons';

import { styled } from '@mui/material/styles';
import { ButtonProps } from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import { isFn } from 'react-toastify/dist/utils';
import ConfirmationModal from './ConfirmationModal';

const StripeCard: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    authToken,
    stripeCustomerId,
    subscriptionType,
    subscriptionId,
    updateProfile,
  } = useUser();
  const {
    paymentMethods,
    customer,
    fetchPaymentMethods,
    deletePaymentMethod,
    updateDefaultPaymentMethod,
    addNewPaymentMethod,
  } = usePayment();
  const [alignment, setAlignment] = React.useState('premium');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedCard, setSelectedCard] = React.useState<any | null>(null);
  const [newCard, setNewCard] = React.useState<boolean>(false);
  const [deleteCardConfirmation, setDeleteCardConfirmation] = React.useState<
    string | null
  >(null);
  const [switchingDefaultCard, setSwitchingDefaultCard] = React.useState<
    string | boolean
  >(false);

  useEffect(() => {
    if (!paymentMethods || paymentMethods?.length === 0) {
      setNewCard(true);
    } else {
      setNewCard(false);
    }
  }, [paymentMethods]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    hidePostalCode: true,
    loader: 'auto',
  };

  const renderCardIcon = (brand: string) => {
    switch (brand) {
      case 'visa':
        return <FontAwesomeIcon icon={faCcVisa} color='#00A0D5' size={'xl'} />;
      case 'mastercard':
        return (
          <FontAwesomeIcon icon={faCcMastercard} color='#F79E1B' size={'xl'} />
        );
      case 'amex':
        return <FontAwesomeIcon icon={faCcAmex} color='#0078D2' size={'xl'} />;
      case 'discover':
        return (
          <FontAwesomeIcon icon={faCcDiscover} color='#FF6000' size={'xl'} />
        );
      case 'jcb':
        return <FontAwesomeIcon icon={faCcJcb} color='#FF6000' size={'xl'} />;
      case 'diners':
        return (
          <FontAwesomeIcon icon={faCcDinersClub} color='#FF6000' size={'xl'} />
        );
      default:
        return (
          <FontAwesomeIcon icon={faCcStripe} color='#FF6000' size={'xl'} />
        );
    }
  };

  const getSwitchCardEntryButton = () => {
    return (
      <div>
        {newCard ? (
          <Grid container justifyContent='center' alignItems='center'>
            {paymentMethods && paymentMethods?.length > 0 && (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ textAlign: 'center', margin: 15 }}
                >
                  <Typography style={{ color: 'gray' }}>Or</Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  onClick={() => {
                    setNewCard(false);
                  }}
                  style={{
                    textAlign: 'center',
                    border: 'solid rgba(0,0,0,0.2) 1px',
                    padding: 16,
                    borderRadius: 4,
                  }}
                >
                  <Typography style={{ color: 'gray', textAlign: 'center' }}>
                    Select an existing card
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <Grid container justifyContent='center' alignItems='center'>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: 'center', margin: 15 }}
            >
              <Typography style={{ color: 'gray' }}>Or</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              onClick={() => {
                setNewCard(true);
              }}
              style={{
                textAlign: 'center',
                border: 'solid rgba(0,0,0,0.2) 1px',
                padding: 16,
                borderRadius: 4,
              }}
            >
              <Typography style={{ color: 'gray', textAlign: 'center' }}>
                Add a new card
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>
    );
  };

  const renderSubscriptionHeader = () => {
    return (
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={{
          marginBottom: 30,
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          style={{ textAlign: 'center' }}
        >
          <ToggleButtonGroup
            color='primary'
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label='Subscription Type'
            size='small'
          >
            <ToggleButton value='premium'>
              Premium <b style={{ paddingLeft: 5 }}> (199$/Month)</b>
            </ToggleButton>
            {/* <ToggleButton value='enterprise'>Enterprise</ToggleButton> */}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    );
  };

  const renderFooterButton = (alreadySubscribed: boolean) => {
    let onClickFunc = () => {};
    let buttonText = 'Purchase Subscription';

    //Not Subscribed, have cards already, trying to create new card

    if (newCard) {
      if (alreadySubscribed) {
        buttonText = 'Add New Card';
        onClickFunc = async () => {
          console.warn('Executing add new card');
          if (isLoading) return;

          if (!stripe || !elements) {
            return;
          }
          setIsLoading(true);
          const card = elements.getElement(CardElement);

          if (card) {
            const { paymentMethod, error } = await stripe.createPaymentMethod({
              type: 'card',
              card: card,
            });

            if (error) {
              console.error(error);
              toast.error(error.message);
              setIsLoading(false);
              return;
            } else {
              try {
                console.warn('stripeCustomerId', stripeCustomerId);

                let addCard = await addNewCard(authToken!, paymentMethod);
                console.warn('new card added', addCard);
                if (addCard.error) {
                  toast.error(addCard.error);
                  setIsLoading(false);
                  return;
                }

                toast.success('You have successfully added new payment method');
                addNewPaymentMethod(addCard.data.data);
                setNewCard(false);
                setIsLoading(false);
              } catch (e: any) {
                console.warn('error', e);
                toast.error(e?.message || 'Something went wrong');
                setIsLoading(false);
              }
            }
          }
        };
      } else {
        //Adding New Card and Subscribe to plan
        onClickFunc = async () => {
          console.warn('Executing add new card');
          if (isLoading) return;

          if (!stripe || !elements) {
            return;
          }
          setIsLoading(true);
          const card = elements.getElement(CardElement);

          if (card) {
            const { paymentMethod, error } = await stripe.createPaymentMethod({
              type: 'card',
              card: card,
            });

            if (error) {
              console.error(error);
              toast.error(error.message);
              setIsLoading(false);
              return;
            } else {
              try {
                console.warn('stripeCustomerId', stripeCustomerId);
                if (!stripeCustomerId) {
                  //create customer and initiate subscription
                  let stripeCustomer = await createStripeCustomer(authToken!);

                  if (stripeCustomer.error) {
                    toast.error(stripeCustomer.error);
                    setIsLoading(false);
                    return;
                  }
                  console.warn('stripeCustomer', stripeCustomer);
                  console.warn([
                    {
                      Name: 'custom:stripeCustomerId',
                      Value: stripeCustomer.data.customer.id,
                    },
                  ]);
                  updateProfile([
                    {
                      Name: 'custom:stripeCustomerId',
                      Value: stripeCustomer.data.customer.id,
                    },
                  ]);
                }
                console.warn('token', paymentMethod);
                let subs = await initiateSubscription(
                  authToken!,
                  alignment,
                  paymentMethod
                );
                console.warn('subs', subs);
                if (subs.error) {
                  toast.error(subs.error);
                  setIsLoading(false);
                  return;
                }

                updateProfile([
                  {
                    Name: 'custom:subscriptionId',
                    Value: subs.data.subscription.id,
                  },
                  {
                    Name: 'custom:subscriptionType',
                    Value: alignment,
                  },
                ]);

                console.warn('subs', subs);
                toast.success(
                  'You have successfully subscribed to ' + alignment + ' plan'
                );
                fetchPaymentMethods(authToken!);
                setIsLoading(false);
                setNewCard(false);
              } catch (e: any) {
                console.warn('error', e);
                toast.error(e?.message || 'Something went wrong');
                setIsLoading(false);
              }
            }
          }
        };
      }
    } else {
      //on existing selecting screen
      if (alreadySubscribed) {
        //Already subscribed and trying to cancel
        buttonText = 'Cancel Subscription';
        onClickFunc = () => {
          if (isLoading) return;
          setIsLoading(true);
          cancelSubscription(authToken!)
            .then((response) => {
              if (response.error) {
                toast.error(response.error);
              } else {
                toast.success(response.data.message);
                updateProfile([
                  {
                    Name: 'custom:subscriptionId',
                    Value: 'none',
                  },
                  {
                    Name: 'custom:subscriptionType',
                    Value: 'none',
                  },
                  {
                    Name: 'custom:slackTeamName',
                    Value: 'none',
                  },
                ]);
              }
              setIsLoading(false);
            })
            .catch((error) => {
              toast.error(error?.message || 'Something went wrong');
              setIsLoading(false);
            });
        };
      } else if (!alreadySubscribed) {
        //Not subscribed and trying to subscribe with existing card
        buttonText = 'Purchase Subscription';
        onClickFunc = async () => {
          if (isLoading) return;
          setIsLoading(true);
          if (selectedCard) {
            console.warn('selectedCard', selectedCard);

            try {
              let subs = await initiateSubscription(
                authToken!,
                alignment,
                selectedCard
              );
              console.warn('subs', subs);
              if (subs.error) {
                toast.error(subs.error);
                setIsLoading(false);
                return;
              }

              updateProfile([
                {
                  Name: 'custom:subscriptionId',
                  Value: subs.data.subscription.id,
                },
                {
                  Name: 'custom:subscriptionType',
                  Value: alignment,
                },
              ]);

              console.warn('subs', subs);
              toast.success(
                'You have successfully subscribed to ' + alignment + ' plan'
              );
              setIsLoading(false);
              setSelectedCard(null);
            } catch (e: any) {
              console.warn('error', e);
              toast.error(e?.message || 'Something went wrong');
              setIsLoading(false);
            }
          } else {
            toast.error('Please select a card to subscribe');
            setIsLoading(false);
          }
        };
      }
    }

    return (
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        style={{ textAlign: 'center', marginTop: 20 }}
      >
        <Button
          disabled={!stripe || isLoading}
          variant='contained'
          color='primary'
          onClick={onClickFunc}
          // startIcon={!loader && <SlackIcon />}
          sx={{
            backgroundColor: '#4A154B',
            '&:hover': { backgroundColor: '#3A0D3B' },
            justifyContent: 'center',
            // width: "230px",
            geight: '50px',
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={'20px'}
              style={{ color: primary.white, padding: 4 }}
            />
          ) : (
            <Typography style={{ paddingTop: 2, paddingBottom: 2 }}>
              {buttonText}
            </Typography>
          )}
        </Button>
      </Grid>
    );
  };

  const renderNewCardInput = () => {
    return (
      <Grid container justifyContent='center' alignItems='center'>
        <div style={{ width: '94%' }}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
      </Grid>
    );
  };

  const renderForm = () => {
    let alreadySubscribed = false;
    let currentTab = alignment;
    let existingCardsCount = paymentMethods?.length || 0;
    if (currentTab === 'enterprise') {
      return (
        <>
          {renderSubscriptionHeader()}
          <Typography
            style={{ color: 'gray', textAlign: 'center', marginBottom: 20 }}
          >
            Please contact us at{' '}
            <b>
              <a style={{ color: 'gray' }} href='mailto:sales@askradar.ai'>
                sales@askradar.ai
              </a>
            </b>
          </Typography>
        </>
      );
    }
    if (
      (subscriptionId && subscriptionId !== 'none') ||
      subscriptionType === 'premium'
    ) {
      alreadySubscribed = true;
    }

    if (!alreadySubscribed) {
      //Not Subscribed
      console.warn('Not Subscribed');
      if (existingCardsCount) {
        //Not Subscribed, have cards already
        console.warn('Not Subscribed, have cards already');
        if (newCard) {
          //Not Subscribed, have cards already, trying to create new card
          console.warn(
            'Not Subscribed, have cards already, trying to create new card'
          );
          return (
            <>
              {renderSubscriptionHeader()}
              {renderNewCardInput()}
              {getSwitchCardEntryButton()}
              {renderFooterButton(alreadySubscribed)}
            </>
          );
        } else {
          //Not Subscribed, have cards already, Card Listing Screen
          console.warn(
            'Not Subscribed, have cards already, Card Listing Screen'
          );
          return (
            <>
              {renderSubscriptionHeader()}
              {renderExistingCards()}
              {getSwitchCardEntryButton()}
              {renderFooterButton(alreadySubscribed)}
            </>
          );
        }
      } else {
        //Not Subscribed, don't have cards
        console.warn("Not Subscribed, don't have cards");
        return (
          <>
            {renderSubscriptionHeader()}
            {renderNewCardInput()}
            {/* {getSwitchCardEntryButton()} */}
            {renderFooterButton(alreadySubscribed)}
          </>
        );
      }
    } else {
      //already subscribed
      console.warn('already subscribed');
      if (existingCardsCount) {
        //already subscribed, have cards

        console.warn('already subscribed, have cards');
        if (newCard) {
          console.warn(
            'already subscribed, have cards, trying to create new card'
          );
          return (
            <>
              {renderSubscriptionHeader()}
              {renderNewCardInput()}
              {getSwitchCardEntryButton()}
              {renderFooterButton(alreadySubscribed)}
            </>
          );
        } else {
          return (
            <>
              {renderSubscriptionHeader()}
              {renderExistingCards()}
              {getSwitchCardEntryButton()}
              {renderFooterButton(alreadySubscribed)}
            </>
          );
        }
      } else {
        //already subscribed, have no cards, not achievable
        console.warn('already subscribed, have no cards, not achievable');
      }
    }

    return (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <CircularProgress
          size={'40px'}
          style={{ color: primary.logoColor, padding: 15 }}
        />
      </div>
    );

    // if (!paymentMethods || paymentMethods?.length === 0) {
    //   return (

    // )
    // }
  };

  const DarkButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    borderRadius: 20,
    '&:disabled': {
      backgroundColor: grey[900],
      color: theme.palette.getContrastText(grey[900]),
    },
  }));

  //convert word to titlecase
  const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt: string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const renderExistingCards = () => {
    return (
      <Grid container style={{ width: '100%', marginTop: 30 }} spacing={0}>
        <Grid item flex={1}>
          {!newCard &&
            alignment !== 'enterprise' &&
            paymentMethods?.map((paymentMethod) => (
              <Card
                onClick={() => {
                  if (
                    subscriptionType === 'premium' ||
                    alignment === 'enterprise'
                  ) {
                    return;
                  }

                  if (selectedCard?.id == paymentMethod.id) {
                    setSelectedCard(null);
                    return;
                  }
                  setSelectedCard(paymentMethod);
                }}
                elevation={0}
                sx={{
                  width: '100%',
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginBottom: 1,
                  border: 'solid rgba(0,0,0,0.2) 1px',
                  backgroundColor:
                    selectedCard?.id === paymentMethod.id
                      ? 'rgba(0, 160, 213,0.2)'
                      : 'white',
                }}
              >
                <CardContent>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Grid item>
                          {renderCardIcon(paymentMethod.card.brand)}
                        </Grid>
                        <Grid
                          item
                          style={{
                            marginLeft: 15,
                          }}
                        >
                          <Typography
                            variant='body1'
                            style={{ fontWeight: 'bold' }}
                          >
                            {toTitleCase(paymentMethod.card.brand)} ending in{' '}
                            {paymentMethod.card.last4}
                          </Typography>
                          <Typography
                            variant='body2'
                            fontSize={11}
                            color={grey[600]}
                            style={{ fontWeight: 'bold' }}
                          >
                            Exp. Date: {paymentMethod.card.exp_month}/
                            {paymentMethod.card.exp_year}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Grid item>
                          {customer?.invoice_settings.default_payment_method ===
                          paymentMethod.id ? (
                            <DarkButton
                              size='small'
                              disabled
                              variant='contained'
                            >
                              Default
                            </DarkButton>
                          ) : switchingDefaultCard === paymentMethod.id ? (
                            <CircularProgress
                              size={'15px'}
                              style={{
                                color: primary.logoColor,
                                padding: 5,
                                marginRight: 10,
                              }}
                            />
                          ) : (
                            <Button
                              size='small'
                              onClick={() => {
                                setSwitchingDefaultCard(paymentMethod.id);
                                updateDefaultPaymentMethod(
                                  authToken!,
                                  paymentMethod.id
                                );
                              }}
                            >
                              Set As Default
                            </Button>
                          )}
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label='delete'
                            disabled={
                              paymentMethods?.length === 1 ||
                              customer?.invoice_settings
                                .default_payment_method === paymentMethod.id
                            }
                            onClick={() => {
                              setDeleteCardConfirmation(paymentMethod.id);

                              // deletePaymentMethod(authToken!, paymentMethod.id)
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <div style={{ width: '80%' }}>
      {renderForm()}

      <ConfirmationModal
        open={deleteCardConfirmation ? true : false}
        text='Are you sure you want to delete payment method? '
        onClose={() => {
          console.warn('handleClose is called');
          setDeleteCardConfirmation(null);
        }}
        onConfirm={() => {
          if (!deleteCardConfirmation) return;
          const pmId = deleteCardConfirmation;
          deletePaymentMethod(authToken!, pmId);
          setDeleteCardConfirmation(null);

          console.warn('onConfirm is called');
        }}
      />
    </div>
  );
};

export default StripeCard;
