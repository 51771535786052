import {
  Box,
  TextField,
  Button,
  Avatar,
  Divider,
  Typography,
  Grid,
  CircularProgress,
  ToggleButtonGroup,
  styled,
  ToggleButton,
} from '@mui/material';
import SlackLoginButton from '../components/SlackLoginButton';
import AppHeader from './../components/AppHeader';
import NavBar from './../components/NavBar';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  doGetUploadUrl,
  doLinkSlack,
  doUpdateProfile,
  getPaymentLink,
  getUserAttributes,
} from '../helpers/api';
import { toast } from 'react-toastify';
import { useUser } from '../helpers/UserContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Elements } from '@stripe/react-stripe-js';
import StripeCard from '../components/StripeCard';
import { loadStripe } from '@stripe/stripe-js';
import { usePayment } from '../helpers/PaymentContext';
import { useTourContext } from '../helpers/TourContext';
import StripeRedirectionCard from '../components/StripeRedirectionCard';
import { useHighLight } from '../helpers/useHighlight';
import OnboardingModal from '../components/OnboardingModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircleCheck,
  faCrown,
} from '@fortawesome/free-solid-svg-icons';
import { cancelSubscription } from '../helpers/api';
import { primary } from '../theme/colors';

// Load Stripe with your publishable key
const stripePromise = loadStripe(
  'pk_test_51NxYelKbpcy8tBTEWtuHtkXYqZGjDFzztnAGe0EXxGyupyRnfzmWsdMDN0kGUg2BW85ttxYLjfbof9Ar6v77qaFK00U4zm2MPN'
);

const basicFeatures = [
  'Weekly Excel Report',
  'Overall Engagement Score',
  'Sentiment Score',
  'Collaboration Score',
  'Productivity Score',
  'Integration With Three Slack Channels',
];

const premiumFeatures = [
  'Weekly Excel Report',
  'Overall Engagement Score',
  'Sentiment Score',
  'Collaboration Score',
  'Productivity Score',
  'Integration With Three Slack Channels',
  'Personalized Dashboard',
  'Word Cloud',
  'Conversational Analysis',
  'Unlimited Slack Channels',
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.2),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [alignment, setAlignment] = useState<string>('premium');
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const {
    authToken,
    firstName,
    lastName,
    email,
    slackTeamName,
    stripeCustomerId,
    subscriptionId,
    subscriptionType,
    updateProfile,
  } = useUser();

  const { addPaymentLink } = usePayment();
  const {
    updateTourState,
    tourState: { tourActive, stepIndex },
  } = useTourContext();
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const { isHighlight, highlightElement, removeHighlightElement } =
    useHighLight();

  const onHighlightElement = () => {
    highlightElement('connectionsExternalHolder');
  };
  const onRemoveHighlightElement = () => {
    removeHighlightElement('connectionsExternalHolder');
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the threshold as needed
      console.log('ScreenSize', isSmallScreen, window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [formFirstName, setFormFirstName] = useState<string>(firstName!);
  const [formLastName, setFormLastName] = useState<string>(lastName!);
  const [linked, setLinked] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    console.warn('Tour status is', tourActive, loading, stepIndex);

    if (tourActive) {
      updateTourState({ run: !loading, stepIndex: 11 });
    } else {
      updateTourState({ run: false });
    }
  }, [loading]);

  useEffect(() => {
    if (!tourActive) {
      setIsOnboardingModalOpen(true);
    }
  }, [tourActive]);

  useEffect(() => {
    getUserAttributes(authToken!).then((response) => {
      if (response.error) console.log(response.error);
      else {
        console.log('attr', response.data);
        if (response.data?.data) {
          console.log('fetched attr', response.data.data);
          updateProfile(response.data.data);

          //check if url contains utm_content=payment_processing
          const currentUrl = window.location.href;
          const queryString = currentUrl.split('?')[1];
          const urlParams = new URLSearchParams(queryString);
          console.warn('urlParams', urlParams);
          const utmContent = urlParams.get('utm_content');
          console.warn('utmContent', utmContent);
          if (utmContent === 'payment_processing') {
            console.warn('payment processing');
            // check if data includes subscriptionId
            let subscriptionId = response.data.data.find(
              (attr: any) => attr.Name === 'custom:subscriptionId'
            );
            console.warn('Subsss', subscriptionId, slackTeamName);
            if (
              subscriptionId &&
              subscriptionId.Value !== 'none' &&
              (!slackTeamName || slackTeamName === 'none')
            ) {
              toast.success(
                'You are succesfully subscribed to the premium plan. Please link your workspace.'
              );
              if (!tourActive) {
                onHighlightElement();
              }
            } else if (!subscriptionId || subscriptionId.Value === 'none') {
              toast.error(
                'There is some problem with your subscription. Please contact customer support.'
              );
            }
          }
        }
      }
    });

    getPaymentLink(authToken!).then((response) => {
      if (response.error) console.log(response.error);
      else {
        console.log('paymentLink', response.data);
        if (response.data?.data) {
          addPaymentLink(response.data.data);
        }
      }
    });
  }, []);

  useEffect(() => {
    const currentUrl = window.location.href;
    const queryString = currentUrl.split('?')[1];
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    if (code && !linked) {
      handleSlackLogin(code);
    } else {
      setLoading(false);
    }

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const handleSlackLogin = async (code: string) => {
    console.log('handleSlackLogin is Called');

    if (code && !linked) {
      console.log('if (code && !linked) is Called');

      const response = await doLinkSlack(authToken!, code);
      if (response.error) {
        console.error('response', response);
        // toast.error("Linking Failed")
      } else {
        console.log('response', response?.data);
        setLinked(true);
        updateProfile([
          {
            Name: 'custom:slackTeamName',
            Value: response?.data?.team_name,
          },
        ]);
        localStorage.setItem('slack_team_name', response?.data?.team_name);
        toast.success(`Connected with ${response?.data?.team_name} team`);
      }
    }
  };

  const handleSaveUpdate = async () => {
    const attributes = [
      {
        Name: 'given_name',
        Value: formFirstName,
      },
      {
        Name: 'family_name',
        Value: formLastName,
      },
    ];
    const response = await doUpdateProfile(authToken!, attributes);

    if (response.error) toast.error(response.error);
    else {
      updateProfile(attributes);
      toast.success('Profile updated successfully');
    }
  };

  const timePeriodOptions = [
    {
      name: 'Premium',
      value: 'premium',
    },
    {
      name: 'Enterprise',
      value: 'enterprise',
    },
  ];

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    console.log('newAlignment', newAlignment);
    setAlignment(newAlignment);
  };

  const handleAlignment = (_event: any, newAlignment: any) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const getFeatureList = (type: string) => {
    if (type === 'basic') {
      return basicFeatures;
    } else if (type === 'premium') {
      return premiumFeatures;
    } else {
      return [];
    }
  };

  const handleUnsubscribe = () => {
    const attr = [
      {
        Name: 'custom:subscriptionId',
        Value: 'none',
      },
      {
        Name: 'custom:subscriptionType',
        Value: 'none',
      },
      {
        Name: 'custom:slackTeamName',
        Value: 'none',
      },
    ];
    cancelSubscription(authToken!)
      .then((response) => {
        if (response.error) {
          toast.error(response.error);
        } else {
          toast.success(response.data.message);
          doUpdateProfile(authToken!, attr)
            .then((responseProfile) => {
              console.log(responseProfile);
              localStorage.removeItem('subscriptionId');
              updateProfile(attr);
            })
            .catch((error) => {
              toast.error(error?.message || 'Something went wrong');
            });
        }
      })
      .catch((error) => {
        toast.error(error?.message || 'Something went wrong');
      });
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {isHighlight && (
        <div
          className={'overlay'}
          onClick={() => {
            onRemoveHighlightElement();
          }}
        />
      )}
      {loading ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.95)', // White background with opacity
            display: loading ? 'flex' : 'none', // Show only when loading is true
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <CircularProgress color='primary' />
        </div>
      ) : (
        <>
          <Box sx={{ position: 'sticky', top: '0px', zIndex: 1 }}>
            <AppHeader toggleDrawer={toggleDrawer} />
          </Box>

          <Grid container alignItems='stretch'>
            <Grid
              item
              sx={{
                backgroundColor: 'white',
                // width: "112.9px",
                // flexBasis: "112.9px",
                borderRight: 1,
                borderColor: '#E3E3E3',
                position: 'sticky',
                top: '64px',
                height: isSmallScreen ? 'auto' : 'calc(100vh - 64px)',
              }}
            >
              <NavBar
                isDrawerOpen={isDrawerOpen}
                toggleDrawer={toggleDrawer}
                onSelect={() => {
                  navigate('/dashboard');
                }}
              />
            </Grid>

            <Grid item xs sx={{ flexGrow: 1 }}>
              <Grid id='1' container spacing={3} sx={{ padding: '36px' }}>
                <Grid item xs={12} md={6}>
                  <Grid
                    id='contactInfoHolder'
                    container
                    item
                    direction='column'
                    sx={{
                      padding: '20px',
                      borderRadius: 3,
                      boxShadow: 5,
                      backgroundColor: 'white',
                      marginBottom: 4,
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography
                        textAlign='left'
                        sx={{
                          fontSize: '25px',
                          fontWeight: 'bold',
                          color: '#404040',
                        }}
                      >
                        Contact Info
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <TextField
                            fullWidth
                            label='First Name'
                            value={formFirstName}
                            onChange={(e) => setFormFirstName(e.target.value)}
                            variant='outlined'
                            sx={{ marginTop: '2rem' }}
                          />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6} xl={6}>
                          <TextField
                            fullWidth
                            label='Last Name'
                            value={formLastName}
                            onChange={(e) => setFormLastName(e.target.value)}
                            variant='outlined'
                            sx={{ marginTop: '2rem' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ flexGrow: 1, width: '100%' }}
                    >
                      <TextField
                        fullWidth
                        label='Email Address'
                        value={email}
                        variant='outlined'
                        sx={{ marginTop: '2rem' }}
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ flexGrow: 1, width: '100%' }}>
                      <Button
                        variant='outlined'
                        fullWidth
                        sx={{ marginTop: '1rem' }}
                        onClick={handleSaveUpdate}
                      >
                        Save & Update
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid
                    id='connectionsHolder'
                    container
                    item
                    spacing={1}
                    direction='column'
                    alignItems='center'
                    sx={{
                      padding: '20px',
                      borderRadius: 3,
                      boxShadow: 5,
                      backgroundColor: 'white',
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        textAlign='center'
                        sx={{
                          fontSize: '25px',
                          fontWeight: 'bold',
                          color: '#404040',
                          paddingBottom: '10px',
                        }}
                      >
                        Connections
                      </Typography>
                      {slackTeamName && slackTeamName !== 'none' && (
                        <Typography style={{ color: 'gray' }}>
                          Connected with <b>{slackTeamName}</b> team
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <SlackLoginButton
                        action={
                          slackTeamName && slackTeamName !== 'none'
                            ? 'unlink'
                            : 'link'
                        }
                        buttonText={
                          slackTeamName && slackTeamName !== 'none'
                            ? `Unlink Slack Workspace`
                            : 'Connect Slack Workspace'
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    direction='column'
                    sx={{
                      padding: '20px',
                      borderRadius: 3,
                      boxShadow: 5,
                      backgroundColor: 'white',
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography
                        textAlign='left'
                        sx={{
                          fontSize: '25px',
                          fontWeight: 'bold',
                          color: '#404040',
                        }}
                      >
                        My Subscription
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={9}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                      }}
                    >
                      <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label='Platform'
                        defaultValue='pw'
                        size='large'
                        sx={{
                          '&.MuiToggleButtonGroup-root': {
                            height: 40,
                            width: { xs: '100%', md: '90%', lg: '80%' },
                            borderRadius: 1,
                            backgroundColor: '#F5F5F5',
                            border: (theme) =>
                              `1px solid ${theme.palette.divider}`,
                          },
                        }}
                      >
                        {timePeriodOptions.map((option, index) => (
                          <>
                            <StyledToggleButtonGroup
                              size='large'
                              value={alignment}
                              exclusive
                              onChange={handleAlignment}
                              aria-label='text alignment'
                              sx={{ width: '100%' }}
                            >
                              <ToggleButton
                                key={option.value}
                                sx={{
                                  textTransform: 'unset',
                                  fontStyle: 'Helvetica',
                                  color: '#5A5A5A',
                                  width: '100%',
                                  '&.Mui-selected, &.Mui-selected:hover': {
                                    boxShadow: '4px 0px 4px #5A5A5A29',
                                    color: '#1A1A1A',
                                    fontWeight: 'bold',
                                    backgroundColor: '#FFFFFF',
                                    borderRaius: '5px',
                                    width: '100%',
                                  },
                                }}
                                value={option.value}
                                selected={alignment === option.value}
                                onChange={(event) =>
                                  handleAlignment(event, option.value)
                                }
                              >
                                <Typography
                                  fontSize={{ xs: '14px', sm: '16px' }}
                                >
                                  {option.name}
                                </Typography>
                              </ToggleButton>
                            </StyledToggleButtonGroup>
                            {alignment === option.value ||
                              (index !== timePeriodOptions.length - 1 && (
                                <Divider
                                  flexItem
                                  orientation='vertical'
                                  sx={{ mx: 0.5, my: 1 }}
                                />
                              ))}
                          </>
                        ))}
                      </ToggleButtonGroup>
                      {/* <TimeToggleGroup /> */}
                    </Grid>
                    {((subscriptionType &&
                      subscriptionType !== 'none' &&
                      subscriptionType === alignment) ||
                      ((!subscriptionType || subscriptionType === 'none') &&
                        alignment === 'basic')) && (
                      <Grid item xs={12}>
                        <Typography
                          style={{ color: '#00856F', fontWeight: 'bold' }}
                        >
                          Your current subscription
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={12} marginBottom={4}>
                      {alignment !== 'enterprise' &&
                        getFeatureList('premium').map((feature, index) => {
                          return (
                            <div
                              style={{
                                marginTop: 5,
                                marginBottom: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                color='#00856F'
                                size={'sm'}
                                type='circle'
                              />
                              <Typography
                                style={{
                                  color: '#626262',
                                  paddingLeft: 10,
                                  fontSize: 14,
                                }}
                              >
                                {feature}
                              </Typography>
                            </div>
                          );
                        })}

                      {/*(!subscriptionType ||
                        subscriptionType === 'basic' ||
                        subscriptionType === 'none') &&
                        alignment === 'premium' && (
                          <Grid item xs={12}>
                            <Typography
                              style={{
                                color: '#1A1A1A',
                                fontWeight: 'bold',
                                marginTop: 10,
                              }}
                            >
                              What you’re missing out on:
                            </Typography>
                          </Grid>
                        )
                        */}

                      {/*alignment === 'premium' &&
                        getFeatureList('premium').map((feature, index) => {
                          return (
                            <div
                              style={{
                                marginTop: 5,
                                marginBottom: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                color={
                                  subscriptionType === 'premium'
                                    ? '#00856F'
                                    : '#626262'
                                }
                                size={'sm'}
                                type='circle'
                              />
                              <Typography
                                style={{
                                  color: '#626262',
                                  paddingLeft: 10,
                                  fontSize: 14,
                                }}
                              >
                                {feature}
                              </Typography>
                            </div>
                          );
                        })
                        */}

                      {alignment === 'enterprise' && (
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCrown}
                            color={primary.black}
                            size={'3x'}
                            type='circle'
                          />
                          <Typography
                            style={{
                              color: '#626262',
                              paddingLeft: 10,
                              fontSize: 16,
                              fontWeight: 'bold',
                              marginTop: 10,
                              marginBottom: 15,
                            }}
                          >
                            Coming Soon
                          </Typography>
                        </div>
                      )}
                    </Grid>

                    {/* <StripeRedirectionNewCard tab={alignment} /> */}
                    {/*alignment === 'premium' && (
                      <Grid item xs={12}>
                        <Button
                          variant='outlined'
                          component='span'
                          sx={{
                            marginTop: '1rem',
                            textTransform: 'none',
                            fontWeight: 'bold',
                          }}
                          fullWidth
                        >
                          Upgrade To Premium
                        </Button>
                      </Grid>
                    )*/}
                    {alignment === 'enterprise' && (
                      <Grid item xs={12}>
                        <Button
                          variant='outlined'
                          component='span'
                          sx={{
                            marginTop: '1rem',
                            textTransform: 'none',
                            fontWeight: 'bold',
                          }}
                          fullWidth
                          onClick={() => {
                            document.open('mailto:sales@askradar.ai', '_blank');
                          }}
                        >
                          Contact Sales
                        </Button>
                      </Grid>
                    )}
                    {alignment === 'premium' &&
                      localStorage.getItem('subscriptionId') && (
                        <Grid item xs={12} md={6}>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={handleUnsubscribe}
                          >
                            Unsubscribe
                          </Button>
                        </Grid>
                      )}

                    {alignment === 'premium' &&
                      !localStorage.getItem('subscriptionId') && (
                        <Grid item xs={12} md={6}>
                          <Grid
                            container
                            spacing={1}
                            direction='column'
                            alignItems='center'
                            sx={{
                              padding: '20px',
                              borderRadius: 3,
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              lg={12}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '70%',
                              }}
                            >
                              <Elements
                                options={{
                                  appearance: {
                                    theme: 'stripe',
                                  },
                                }}
                                stripe={stripePromise}
                              >
                                <StripeCard />
                              </Elements>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {/* <OnboardingModal open={isOnboardingModalOpen} handleClose={() => setIsOnboardingModalOpen(false)} /> */}
    </Box>
  );
};

export default ProfilePage;
