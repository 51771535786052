import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  Box,
  Typography,
  SvgIcon,
  IconButton,
  Drawer,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import GroupsIcon from "@mui/icons-material/Groups";
import { ReactComponent as Home } from "../assets/home.svg";
import { ReactComponent as Chat } from "../assets/chat.svg";
import { ReactComponent as Group } from "../assets/group.svg";
import { ReactComponent as Chart } from "../assets/chart.svg";
import { Image, Menu as MenuIcon } from "@mui/icons-material";
import { ReactComponent as Logo } from "../assets/logo.svg";

import { Link, useLocation } from "react-router-dom";

const NavBar: React.FC<{
  onSelect: (selected: string) => void; 
  isDrawerOpen: boolean; 
  toggleDrawer: () => void}> = ({ onSelect, isDrawerOpen, toggleDrawer }) => {
  const [selectedOption, setSelectedOption] = useState("engagementDashboard");

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the threshold as needed
      console.log("ScreenSize", isSmallScreen, window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    onSelect(option);
  };
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    const lastPartOfPath = currentPath.substr(currentPath.lastIndexOf("/") + 1);
    console.log("Navbar", lastPartOfPath);
    setSelectedOption("/" + lastPartOfPath);
  }, [location]);



  const navigationItems = [
    {
      id: "/engagementDashboard",
      label: "Engagement Dashboard",
      icon: <Home height={"40px"} width={"40px"} fontWeight={"bold"} />,
    },
    {
      id: "/conversation-breakdown",
      label: "Conversation Breakdown",
      icon: <Chat height={"40px"} width={"40px"} fontWeight={"bold"} />,
    },
    {
      id: "/manage-reporting",
      label: "Manage Reporting",
      icon: <Chart height={"40px"} width={"40px"} fontWeight={"bold"} />,
    },
    {
      id: "/manage-channels",
      label: "Manage Channels",
      icon: <Group height={"40px"} width={"40px"} fontWeight={"bold"} />,
    },
  ];
  // useEffect(() => {
  //   const currentURL = window.location.href;
  //   const urlParts = currentURL.split("/");
  //   const variableContainingValue = urlParts[urlParts.length - 1];
  //   console.log("Current Page", variableContainingValue);
  //   setSelectedOption("/" + variableContainingValue);
  //   onSelect("/" + variableContainingValue);
  // }, []);

  const isOptionSelected = (option: string) => {
    return option === selectedOption;
  };



  return (
    <>
      {isSmallScreen ? (
        <>
          <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>
          <Box
          display="flex"
          alignItems="center"
          sx={{ backgroundColor: "#00A0D5", height: "64px" }}
        >
          {/* <Logo/> */}
          <SvgIcon
            component={Logo}
            viewBox="0 0 1144 1144"
            sx={{ width: "100%", height: "100%" }}
          />
        </Box>
            <List>
              {navigationItems.map((item) => (
                <Link
                  key={item.id}
                  style={{ color: "#000000", textDecoration: "none" }}
                  to={"/dashboard" + item.id}
                >
                  <ListItem
                    button
                    onClick={() => {
                      handleOptionSelect(item.id);
                      toggleDrawer();
                    }}
                    style={{
                      backgroundColor: isOptionSelected(item.id)
                        ? "#EFEFEF"
                        : "transparent",
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography
                      style={{
                        fontWeight: isOptionSelected(item.id)
                          ? "bold"
                          : "unset",
                      }}
                      variant="body2"
                    >
                      {item.label} 
                    </Typography>
                    {/* <Typography
                      style={{
                        fontWeight: isOptionSelected(item.id)
                          ? "bold"
                          : "unset",
                        fontStyle: "Roboto",
                      }}
                      variant="body2"
                    >
                      {item.label.split(" ")[1]} 
                    </Typography> */}
                  </ListItem>
                </Link>
              ))}
            </List>
          </Drawer>
        </>
      ) : (
        <Box style={{ width: "112.9px" }}>
          {navigationItems.map((item) => (
            <List>
              <Link
                style={{ color: "#000000", textDecoration: "none" }}
                to={"/dashboard" + item.id}
              >
                <ListItem
                  key={item.id}
                  button
                  onClick={() => handleOptionSelect(item.id)}
                  style={{
                    backgroundColor: isOptionSelected(item.id)
                      ? "#EFEFEF"
                      : "transparent",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80px",
                    paddingLeft: "16px",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <ListItemIcon
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>{" "}
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      marginLeft="8px"
                    >
                      <Typography
                        style={{
                          fontWeight: isOptionSelected(item.id)
                            ? "bold"
                            : "unset",
                        }}
                        variant="body2"
                      >
                        {item.label.split(" ")[0]} {/* First line */}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: isOptionSelected(item.id)
                            ? "bold"
                            : "unset",
                          fontStyle: "Roboto",
                        }}
                        variant="body2"
                      >
                        {item.label.split(" ")[1]} {/* Second line */}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              </Link>
            </List>
          ))}
        </Box>
      )
}
</>
  );
};

export default NavBar;
