import React from 'react';
import DashboardComponent from '../components/DashboardComponent';
import { Box } from '@mui/system';
import ManageReporting from '../components/ManageReporting';

const ManageReportingPage: React.FC = () => {
    return (
        <Box
            sx={{
                backgroundColor: '#ffffff',
                height: '100%',
            }}
        >
            <ManageReporting />
        </Box>
    );
};

export default ManageReportingPage;