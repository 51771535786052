import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './helpers/UserContext';
import PrivateRoute from './helpers/PrivateRoute';
import RegularPage from './pages/RegularPage';
import SplitPage from './pages/SplitPage';
import SignInComponent from './components/SignInComponent';
import ResetPasswordComponent from './components/ResetPasswordComponent';
import ForgotPasswordComponent from './components/ForgotPasswordComponent';
import SignUpComponent from './components/SignUpComponent';
import NotFoundComponent from './components/NotFoundComponent';
import ManageReportingPage from './pages/ManageReportingPage';
import ManageChannelsPage from './pages/ManageChannelsPage';
import ConversationBreakdownPage from './pages/ConversationBreakdownPage';
import { ScoreProvider } from './helpers/ScoreContext';
import ProfileComponent from './components/ProfileComponent';
import { ChannelProvider } from './helpers/ChannelContext';
import EngagementDashboard from './components/EngagementDashboard';
import { PaymentProvider } from './helpers/PaymentContext';
import { TourProvider, useTourContext } from './helpers/TourContext';
import Joyride, { CallBackProps, ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { primary } from './theme/colors';
import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import UnsubscribeEmailComponent from './pages/UnsubscribeEmail';
import SignUpEnterpriseComponent from './components/SignUpEnterpriseComponent';
import VerifyEmail from './pages/VerifyEmail';
// import WalkthroughComponent from "./components/WalkthroughComponent";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <ScoreProvider>
          <ChannelProvider>
            <PaymentProvider>
              {/* <TourProvider> */}
              <Router>
                <Routes>
                  <Route
                    path='/'
                    element={<SplitPage component={SignInComponent} />}
                  />
                  <Route
                    path='/login'
                    element={<SplitPage component={SignInComponent} />}
                  />
                  <Route
                    path='/subscribe'
                    element={<SplitPage component={SignUpComponent} />}
                  />
                  <Route
                    path='/enterprise-signup'
                    element={
                      <SplitPage component={SignUpEnterpriseComponent} />
                    }
                  />
                  <Route
                    path='/signup'
                    element={<SplitPage component={SignUpComponent} />}
                  />
                  <Route
                    path='/forgot-password'
                    element={<SplitPage component={ForgotPasswordComponent} />}
                  />
                  <Route
                    path='/reset-password'
                    element={<SplitPage component={ResetPasswordComponent} />}
                  />
                  <Route
                    path='/unsubscribe-email'
                    element={
                      <RegularPage component={UnsubscribeEmailComponent} />
                    }
                  />
                  <Route
                    path='/verify-user'
                    element={<RegularPage component={VerifyEmail} />}
                  />
                  <Route
                    path='/dashboard'
                    element={<PrivateRoute component={DashboardPage} />}
                  >
                    <Route
                      path='engagementDashboard'
                      element={<PrivateRoute component={EngagementDashboard} />}
                    />

                    <Route
                      path='manage-reporting'
                      element={<PrivateRoute component={ManageReportingPage} />}
                    />

                    <Route
                      path='manage-channels'
                      element={<PrivateRoute component={ManageChannelsPage} />}
                    />

                    <Route
                      path='conversation-breakdown'
                      element={
                        <PrivateRoute component={ConversationBreakdownPage} />
                      }
                    />
                  </Route>
                  <Route
                    index
                    path='profile'
                    element={<PrivateRoute component={ProfilePage} />}
                  />
                  <Route
                    path='*'
                    element={<RegularPage component={NotFoundComponent} />}
                  />
                </Routes>
              </Router>
              <ToastContainer />
              {/* <WalkthroughComponent /> */}
              {/* </TourProvider> */}
            </PaymentProvider>
          </ChannelProvider>
        </ScoreProvider>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
