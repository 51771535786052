import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { primary } from "./colors";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { rows } from "../constants/constants";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: primary.headerGray,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CATable({ sentence }: { sentence: any[] }) {
  // console.log("sentence", sentence);
  function highlightText(
    sentence: string,
    highlightedText: string,
    sentiment: "positive" | "neutral" | "negative"
  ) {
    const startIndex = sentence
      .toLowerCase()
      .indexOf(highlightedText.toLowerCase());

    console.log("startIndex", startIndex, highlightedText, sentence);

    if (startIndex !== -1) {
      const beforeText = sentence.substring(0, startIndex);
      const afterText = sentence.substring(
        startIndex + highlightedText.length,
        sentence.length
      );

      return (
        <Typography>
          {beforeText}
          <span
            style={{
              color: primary[sentiment],
              fontWeight: "bold",
              padding: 5,
              backgroundColor: primary[`${sentiment}Faded`],
            }}
          >
            {highlightedText}
          </span>
          {afterText}
        </Typography>
      );
    }

    return sentence;
  }

  return (
    <TableContainer sx={{ height: "400px" }} component={Paper}>
      <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              width={"180px"}
              style={{ fontWeight: "bold" }}>
              Mood
            </StyledTableCell>
            <StyledTableCell style={{ fontWeight: "bold" }} align="left">
              Comments
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sentence &&
            sentence.map((row: any) => {
              return (
                <TableRow key={row.comments}>
                  <StyledTableCell
                    style={{
                      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                      backgroundColor:
                        row.sentiment === "positive"
                          ? "#f2f9f8"
                          : row.sentiment === "neutral"
                            ? "#fdf8f3"
                            : "#fbf2f4",
                    }}
                    align="right"
                    width={"180px"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color:
                          row.sentiment === "positive"
                            ? "#00856F"
                            : row.sentiment === "neutral"
                              ? "#DB7D0A"
                              : "#B00020",
                      }}
                    >
                      {row.sentiment === "positive" && (
                        <SentimentSatisfiedAltIcon fontSize="large" />
                      )}
                      {row.sentiment === "neutral" && (
                        <SentimentNeutralIcon fontSize="large" />
                      )}
                      {row.sentiment === "negative" && (
                        <SentimentVeryDissatisfiedIcon fontSize="large" />
                      )}
                      <Typography
                        style={{
                          margin: "5px 0px 0px 2px",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                        textTransform={"capitalize"}
                      >
                        {row.sentiment}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {highlightText(row.sentence, row.highlight, row.sentiment)}
                  </StyledTableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
