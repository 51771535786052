import { Box, Button, CircularProgress, FormControl, Modal, Typography } from '@mui/material';
import React from 'react'
import { connectWorkspaceModalStyle, modalStyle, onboardingModalStyle } from '../theme/styles';
import YoutubeEmbed from './YoutubeEmbed';
import SlackLoginButton from './SlackLoginButton';
import { primary } from '../theme/colors';
import { usePayment } from '../helpers/PaymentContext';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome'
import { faCloud, faLink } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import { useUser } from '../helpers/UserContext';
import { doUnLinkSlack } from '../helpers/api';
import { toast } from 'react-toastify';


const ConnectWorkspaceModal: React.FC<{ open: boolean, handleClose: (evt: any) => void }> = ({
  open,
  handleClose
}) => {
  // const { paymentLink, paymentLinkAmount } = usePayment()
  const navigate = useNavigate()
  const [loader, setLoader] = React.useState<boolean>(false)
  const { authToken, } = useUser()

  const handleClick = async (action: string = 'link') => {
    if (loader) {
      return
    }
    setLoader(true)
    if (action === "link") {
      window.location.href = process.env.REACT_APP_SLACK_AUTH_URL + window.location.origin + "/profile" || "";
    } else if (action === "unlink") {
      console.log("Unlink is Pressed");
      const response = await doUnLinkSlack(authToken!);
      if (response.error) {
        console.error("response", response);
        //   toast.error("Linking Failed")
      } else {
        console.log("response", response?.data);
        localStorage.removeItem("slack_team_name");
        toast.success(response?.data?.message);
        setLoader(false)
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Add Channel Modal"
      aria-describedby="Use to link slack channel"
      style={{
        zIndex: 10000
      }}
    >
      <Box sx={connectWorkspaceModalStyle}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          margin: 10,
          paddingLeft: 10,
        }}>
          <FontAwesomeIcon icon={faCloud} color='black' size={'2xl'} />

          <Typography
            margin={"10px"}
            fontWeight={"bold"}
            textAlign={"center"}
            variant='h5'
            id="modal-add-channel"
            component="h5"
          >
            Connect a Slack Workspace
          </Typography>
        </div>
        <Typography
          style={{
            margin: 10,
            marginLeft: 20,
            marginRight: 20,
          }}
          textAlign={"left"}
          variant='body2' >
          Get started by connecting Maxwell to a Slack workspace. To add more workspaces and/or channels, <Typography style={{
            textDecoration: 'underline',
            display: 'inline',
            fontWeight: 'bold'

          }}>upgrade to PREMIUM</Typography> at anytime.
        </Typography>


        <Box sx={{
          mt: 4, mb: 0, display: 'flex', justifyContent: 'center', backgroundColor: 'rgb(244,244,244)',
          borderBottomLeftRadius: 3, borderBottomRightRadius: 3,
          padding: 2,
          paddingLeft: 6,
          paddingRight: 6,
        }}>
          <Button
            disabled={loader}
            fullWidth
            // href={process.env.REACT_APP_SLACK_AUTH_URL}
            onClick={() => {
              // navigate('/profile')
              handleClick('link')
              // if (paymentLink) {
              //   window.location.href = paymentLink
              // }
            }}
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#097BB9",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": { backgroundColor: "#097BB9" },
              justifyContent: "center",
              alignItems: "center",
              // width: "230px",
            }}
          >

            {loader ? (
              <CircularProgress
                size={"10px"}
                style={{ color: primary.white, }}
              />
            ) : (
              <>
                <FontAwesomeIcon icon={faLink} color='white' size={'sm'} style={{
                  marginRight: 10
                }} />
                <Typography style={{ fontSize: 12, fontWeight: 'bold' }} >Connect a Slack Workspace</Typography>
              </>
            )}

          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConnectWorkspaceModal
