import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react'
import { confirmationModalStyle, onboardingModalStyle } from '../theme/styles';

const ConfirmationModal: React.FC<{ open: boolean, text: string, onConfirm: (evt: any) => void, onClose: (evt: any) => void }> = ({
  open,
  text,
  onConfirm,
  onClose
}) => {


  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Confirmation Modal"
      aria-describedby="Use to confirm action"
      style={{
        zIndex: 10000
      }}
    >
      <Box sx={confirmationModalStyle}>
        <Typography
          margin={"10px"}
          fontWeight={"bold"}
          textAlign={"center"}
          id="modal-add-channel"
          variant="h6"
          component="h2"
        >
          Confirmation
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}
          textAlign={'center'}
        >
          {text}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "10px",
            marginTop: '15px'
          }}

        >


          <Button
            variant="outlined"
            color="primary"
            size={'small'}
            onClick={onClose}
            sx={{
              width: "100px",
              fontWeight: "bold",
              textTransform: "none",
              marginRight: "10px"
            }}
          >
            NO
          </Button>

          <Button
            variant={'contained'}
            color="primary"
            onClick={onConfirm}
            size={'small'}
            sx={{
              width: "100px",
              fontWeight: "bold",
              textTransform: "none",
            }}
          >
            YES
          </Button>


        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmationModal