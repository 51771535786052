import React, { useState } from 'react';
import {
  Button, TextField, Typography, Box, Link
} from '@mui/material';
import { MailOutline, Person2Outlined, Person3Outlined, PersonOutline, Visibility, VisibilityOff, Pin, BusinessCenterOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { doSignUp, doSignUpEnterprise } from '../helpers/api';

const SignUpEnterpriseComponent: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    organizationName: '',
    email: '',
    password: '',
    confirmPassword: '',
    promoCode: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    const response = await doSignUpEnterprise(formData.firstName, formData.lastName, formData.email, formData.password, formData.confirmPassword, formData.promoCode?.trim(), formData.organizationName);
    setIsLoading(false);

    if (response.error)
      toast.error(response.error);
    else {
      navigate('/login');
      toast.success('Successfully signed up! Please log in.');
    }
  };

  const pageHeight = window.innerHeight;

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Box sx={{ textAlign: 'center', }} >
          {/* <img src={require('../assets/full-logo.png')} width={'250px'} alt={'Logo'} /> */}
          <Typography color={'#FFFFFF'} fontSize={'30px'} fontWeight={'bold'} style={{ margin: 10 }} >
            Get Started
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: '#ffffff',
            padding: 3,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography variant="h5" gutterBottom align="center" fontWeight="bold">
            Sign Up
          </Typography>

          <div >
            <TextField
              label="Enterprise Code"
              variant="outlined"
              fullWidth
              margin="normal"
              type="text"
              value={formData.promoCode}
              onChange={(e) => setFormData({ ...formData, promoCode: e.target.value })}
              InputProps={{
                style: {
                  paddingRight: '50px'
                },
                endAdornment: <Pin
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }} />,
              }}
            />

            <Typography variant="body2" align="center" sx={{ marginTop: 2 }}>
              If you do not have an enterprise code, please contact us at <a href="mailto:nils.bunde@askradar.ai">nils.bunde@askradar.ai</a>
            </Typography>



            <TextField
              label="Enter First Name"
              variant="outlined"
              fullWidth
              margin="normal"
              type="text"
              value={formData.firstName}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              InputProps={{
                style: {
                  paddingRight: '50px'
                },
                endAdornment: <PersonOutline
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }} />,
              }}
            />
            <TextField
              label="Enter Last Name"
              variant="outlined"
              fullWidth
              margin="normal"
              type="text"
              value={formData.lastName}
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              InputProps={{
                style: {
                  paddingRight: '50px'
                },
                endAdornment: <PersonOutline
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }} />,
              }}
            />

            <TextField
              label="Enter Organization Name"
              variant="outlined"
              fullWidth
              margin="normal"
              type="text"
              value={formData.organizationName}
              onChange={(e) => setFormData({ ...formData, organizationName: e.target.value })}
              InputProps={{
                style: {
                  paddingRight: '50px'
                },
                endAdornment: <BusinessCenterOutlined
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }} />,
              }}
            />
            <TextField
              label="Enter Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              InputProps={{
                style: {
                  paddingRight: '0px'
                },
                endAdornment: <MailOutline
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }} />,
              }}
            />
            <TextField
              label="Enter Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              InputProps={{
                style: {
                  paddingRight: '50px'
                },
                endAdornment: (
                  <Button
                    sx={{
                      position: 'absolute',
                      right: '0px',
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </Button>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type={showPassword ? 'text' : 'password'}
              value={formData.confirmPassword}
              onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
              InputProps={{
                style: {
                  paddingRight: '50px'
                },
                endAdornment: (
                  <Button
                    sx={{
                      position: 'absolute',
                      right: '0px',
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </Button>
                ),
              }}
            />
          </div>
          <Typography variant="body2" align="center" sx={{ marginTop: 2 }}>
            I agree to the <a target="_blank" href='https://www.askradar.ai/termsofservice'>Terms of Service</a> for AskRadar.ai.
          </Typography>
          <Button variant="contained" color="primary" fullWidth sx={{ marginTop: 3 }} type="submit" disabled={isLoading}>
            {isLoading ? 'Signing Up...' : 'Sign Up'}
          </Button>
          <Typography variant="body2" align="center" sx={{ marginTop: 2 }}>
            Already signed in?{' '}
            <Link href="/login" underline="hover">
              Log in
            </Link>
          </Typography>
        </Box>
      </Box>
    </form>
  );
};

export default SignUpEnterpriseComponent;
