export const modalStyle = {
  position: 'absolute',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid',
  boxShadow: 24,
  p: 4,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)', // Center the modal
  borderRadius: 2
};

export const onboardingModalStyle = {
  position: 'absolute',
  width: '90%',
  maxWidth: '500px',
  bgcolor: 'background.paper',
  // border: '2px solid',
  boxShadow: 24,
  p: 4,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)', // Center the modal
  borderRadius: 2,

};

export const connectWorkspaceModalStyle = {
  position: 'absolute',
  width: '90%',
  maxWidth: '450px',
  bgcolor: 'background.paper',
  // border: '2px solid',
  boxShadow: 24,
  p: 4,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)', // Center the modal
  borderRadius: 1,
  padding: '0px',
  margin: '0px',

};

export const confirmationModalStyle = {
  position: 'absolute',
  width: '20%',
  minWidth: '350px',
  bgcolor: 'background.paper',
  // border: '2px solid',
  boxShadow: 24,
  p: 2,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)', // Center the modal
  borderRadius: 2
};