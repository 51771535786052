import React from "react";
import { Box } from "@mui/system";
import ConversationBreakdown from "../components/ConversationBreakdown";

const ConversationBreakdownPage: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        height: "100%",
      }}
    >
      <ConversationBreakdown />
    </Box>
  );
};

export default ConversationBreakdownPage;
