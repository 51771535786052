export const primary = {
  positive: "#008565",
  positiveFaded: "rgba(0, 133, 101, 0.1)",
  neutral: "#DB7D0A",
  neutralFaded: "rgba(219, 125, 10, 0.1)",
  negative: "#B00020",
  negativeFaded: "rgba(176, 0, 32, 0.1)",
  logoColor: "#097BB9",
  textGray: "#757575",
  headerGray: "#F2F2F2",
  white: "#FFFFFF",
  black: "#000000",
};
