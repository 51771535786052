import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { Attribute } from './api';

type ScoresSchema = {
  engagement: number;
  sentiment: number;
  collaboration: number;
  productivity: number;
}

type ScoreState = {
  weeklyScores: ScoresSchema | null;
  monthlyScores: ScoresSchema | null;
  quarterlyScores: ScoresSchema | null;
  setScores: (type: string, scores: ScoresSchema) => void;
  clearScores: () => void;

};

export const ScoreContext = createContext<ScoreState>({
  weeklyScores: null,
  monthlyScores: null,
  quarterlyScores: null,
  setScores: () => { },
  clearScores: () => { },

});

export const ScoreProvider = (props: PropsWithChildren) => {
  const [weeklyScores, setWeeklyScores] = useState<ScoresSchema | null>(null);
  const [monthlyScores, setMonthlyScores] = useState<ScoresSchema | null>(null);
  const [quarterlyScores, setQuarterlyScores] = useState<ScoresSchema | null>(null);

  const setScores = (type: string, scores: ScoresSchema) => {
    switch (type) {
      case 'week':
        setWeeklyScores(scores);
        break;
      case 'month':
        setMonthlyScores(scores);
        break;
      case 'quarter':
        setQuarterlyScores(scores);
        break;
    }
  }
  const clearScores = () => {
    console.log("clearScores Called");

    setWeeklyScores(null);
    setMonthlyScores(null);
    setQuarterlyScores(null);

  };


  return (
    <ScoreContext.Provider value={{ weeklyScores, monthlyScores, quarterlyScores, setScores, clearScores }}>
      {props.children}
    </ScoreContext.Provider>
  );
};

export const useScores = (): ScoreState => {
  const context = useContext(ScoreContext);
  if (context === undefined) {
    throw new Error('useScores must be used within a scoreProvider');
  }
  return context;
};
