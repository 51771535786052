import React from "react";
import DashboardComponent from "../components/DashboardComponent";
import { Box } from "@mui/system";
import ManageChannels from "../components/ManageChannels";

const ManageChannelsPage: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        height: "100%",
      }}
    >
      <ManageChannels />
    </Box>
  );
};

export default ManageChannelsPage;
