import EngagementDropdown from './EngagementDropdown';
import TimeToggleGroup from './TimeToggleGroup';
import EngagementDetails from './EngagementDetails';
import ScoreModal from './ScoreModal';
import './DashboardComponent.css';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  SvgIcon,
  Typography,
  Button,
} from '@mui/material';
import { useEffect, useState } from 'react';
import SVGScoreModal from './SVGSocreModal';
import { useScores } from '../helpers/ScoreContext';
import { doLoadChannels, doLoadDashboardData } from '../helpers/api';
import { useUser } from '../helpers/UserContext';
import { useChannel } from '../helpers/ChannelContext';
import { useTourContext } from '../helpers/TourContext';
import { primary } from '../theme/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import TrendlinesComponent from './TrendlinesComponent';
import { usePayment } from '../helpers/PaymentContext';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.2),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const EngagementDashboard: React.FC = () => {
  const { weeklyScores, monthlyScores, quarterlyScores, setScores } =
    useScores();
  const paymentLink = `https://buy.stripe.com/dR68xy0NS4Bm0ScdQZ?utm_content=payment_processing&prefilled_email=${localStorage.getItem(
    'email'
  )}`;
  const [loading, setLoading] = useState(false);

  const { updateTourState } = useTourContext();

  const storedButtons = localStorage.getItem('buttonPriority');
  const initialButtons = storedButtons
    ? JSON.parse(storedButtons)
    : ['Overall Mood', 'Collaboration', 'Productivity'];

  const [type, setType] = useState(initialButtons);
  const { authToken } = useUser();

  const [alignment, setAlignment] = useState<string>('week');
  const [channel, setChannel] = useState<string>('all');
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [tourTaken, setTourTaken] = useState(
    localStorage.getItem('tourTaken') === 'true' ? true : false
  );
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the threshold as needed
      console.log('ScreenSize', isSmallScreen, window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const storedButtons = localStorage.getItem('buttonPriority');
    if (storedButtons) {
      console.log(JSON.parse(storedButtons));
      setType(JSON.parse(storedButtons));
    }
  }, []);

  // useEffect(() => {
  //   doLoadChannels(authToken!, "subscribed", false)
  //     .then((response) => {
  //       if (response.error) console.log(response.error);
  //       else {
  //         console.log(response.data);
  //         setChannels("subscribed", response?.data?.channels || []);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  useEffect(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    doLoadDashboardData(
      authToken!,
      alignment,
      channel !== 'all' ? channel : null
    ).then((response) => {
      if (response.error) {
        console.log(response.error);
        setLoading(false);
      } else {
        console.warn(response.data);
        setScores(alignment, response.data);
        setLoading(false);
        window.scrollTo(0, 0);
        if (!tourTaken) {
          updateTourState({ run: true, tourActive: true });
          setTourTaken(true);
          localStorage.setItem('tourTaken', 'true');
        }
      }
    });
  }, [alignment, channel]);

  // const scores = {
  //   engagement: 58,
  //   mood: 9,
  //   collaboration: 4.7,
  //   productivity: 3.3,
  // };
  const timePeriodOptions = [
    {
      name: 'Past Week',
      value: 'week',
    },
    {
      name: 'Past Month',
      value: 'month',
    },
    {
      name: 'Past 3 Months',
      value: 'quarter',
    },
  ];
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    console.log('newAlignment', newAlignment);
    setAlignment(newAlignment);
  };

  const handleAlignment = (_event: any, newAlignment: any) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const getScores = () => {
    if (alignment === 'week') return weeklyScores;
    if (alignment === 'month') return monthlyScores;
    if (alignment === 'quarter') return quarterlyScores;
    return { engagement: 0, sentiment: 0, collaboration: 0, productivity: 0 };
  };

  const loaderStyle = {
    border: '8px solid #f3f3f3',
    borderTop: '8px solid #3498db',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    animation: 'spin 2s linear infinite',
  };

  const keyframesStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
  const { subscriptionId, subscriptionType, slackTeamName } = useUser();

  return (
    <Box p={4}>
      <>
        {loading ? (
          <div
            style={{
              position: 'fixed',
              top: 60,
              left: !isSmallScreen ? 114 : 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.7)', // White overlay background
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999, // Make sure the loader is on top
            }}
          >
            <div style={loaderStyle}></div>
          </div>
        ) : null}
        <Grid
          container
          gap={4}
          direction='column'
          style={{ paddingBottom: '80px' }}
        >
          {(!subscriptionType ||
            subscriptionType === 'none' ||
            subscriptionType === 'basic') &&
            paymentLink && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  backgroundColor: primary.logoColor,
                  borderRadius: 5,
                  display: 'flex',
                  flexDirection: 'row',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    background:
                      'transparent linear-gradient(156deg, #097BB9 0%, #002E46 100%) 0% 0% no-repeat padding-box',
                    height: 90,
                    width: 90,
                    borderRadius: '10px 0px 0px 10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUnlockAlt}
                    color='white'
                    size={'4x'}
                  />
                </div>
                <div style={{ padding: 15, flex: 1 }}>
                  <Typography fontWeight={'bold'} color={primary.white}>
                    Unlock Premium Features!
                  </Typography>

                  <Typography color={primary.white}>
                    Access to a conversational analysis, unlimited Slack
                    channels, word clouds, and more!
                  </Typography>
                </div>

                <Button
                  onClick={() => {
                    window.location.href = paymentLink;
                  }}
                  variant={'outlined'}
                  style={{
                    backgroundColor: primary.white,
                    borderColor: primary.white,
                    fontWeight: 'bold',
                    textTransform: 'none',
                    marginRight: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '5px 35px',
                  }}
                >
                  <Typography
                    fontSize={'14px'}
                    textTransform='none'
                    fontWeight={'bold'}
                    sx={{ color: primary.logoColor }}
                  >
                    Start FREE Premium Trial
                  </Typography>

                  <Typography
                    fontSize={'11px'}
                    textTransform='none'
                    sx={{ color: primary.logoColor, fontWeight: '500' }}
                  >
                    (No payment for 40 days)
                  </Typography>
                </Button>
              </Grid>
            )}

          <Grid item>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <div id='channelList'>
                    <EngagementDropdown
                      onChange={(e) => setChannel(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={9}>
                  <div id='durationTab'>
                    <ToggleButtonGroup
                      value={alignment}
                      exclusive
                      onChange={handleChange}
                      aria-label='Platform'
                      defaultValue='pw'
                      size='large'
                      sx={{
                        '&.MuiToggleButtonGroup-root': {
                          height: 40,
                          width: { xs: '100%', md: '60%' },
                          borderRadius: 1,
                          backgroundColor: '#F5F5F5',
                          border: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                        },
                      }}
                    >
                      {timePeriodOptions.map((option, index) => (
                        <>
                          <StyledToggleButtonGroup
                            size='large'
                            value={alignment}
                            exclusive
                            onChange={handleAlignment}
                            aria-label='text alignment'
                            sx={{ width: '100%' }}
                          >
                            <ToggleButton
                              key={option.value}
                              sx={{
                                textTransform: 'unset',
                                fontStyle: 'Helvetica',
                                color: '#5A5A5A',
                                width: '100%',
                                '&.Mui-selected, &.Mui-selected:hover': {
                                  boxShadow: '4px 0px 4px #5A5A5A29',
                                  color: '#1A1A1A',
                                  fontWeight: 'bold',
                                  backgroundColor: '#FFFFFF',
                                  borderRaius: '5px',
                                  width: '100%',
                                },
                              }}
                              value={option.value}
                              selected={alignment === option.value}
                              onChange={(event) =>
                                handleAlignment(event, option.value)
                              }
                            >
                              <Typography fontSize={{ xs: '14px', sm: '16px' }}>
                                {option.name}
                              </Typography>
                            </ToggleButton>
                          </StyledToggleButtonGroup>
                          {alignment === option.value ||
                            (index !== timePeriodOptions.length - 1 && (
                              <Divider
                                flexItem
                                orientation='vertical'
                                sx={{ mx: 0.5, my: 1 }}
                              />
                            ))}
                        </>
                      ))}
                    </ToggleButtonGroup>
                  </div>
                  {/* <TimeToggleGroup /> */}
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid
            xs={12}
            lg={11}
            md={11}
            sx={{
              borderRadius: 3,
              boxShadow: 3,
            }}
            style={{ padding: '20px' }}
            id='engagementScoreHolder'
          >
            <EngagementDetails
              engagementScore={(getScores()?.engagement || 0) * 10}
            />
          </Grid>

          <Grid item>
            <Grid container gap={3} id='categoryScoreHolder'>
              <Grid
                item
                xs={12}
                sm={8}
                md={5}
                lg={3.5}
                id='collaborationHolder'
              >
                <SVGScoreModal
                  title={type[0]}
                  score={
                    type[0] === 'Overall Mood'
                      ? getScores()?.sentiment || 0
                      : type[0] === 'Collaboration'
                      ? getScores()?.collaboration || 0
                      : getScores()?.productivity || 0
                  }
                />
              </Grid>
              <Grid item xs={12} sm={8} md={5} lg={3.5} id='moodHolder'>
                <ScoreModal
                  title={type[1]}
                  score={
                    type[1] === 'Overall Mood'
                      ? getScores()?.sentiment || 0
                      : type[1] === 'Collaboration'
                      ? getScores()?.collaboration || 0
                      : getScores()?.productivity || 0
                  }
                />
              </Grid>
              <Grid item xs={12} sm={8} md={5} lg={3.5} id='productivityHolder'>
                <ScoreModal
                  title={type[2]}
                  score={
                    type[2] === 'Overall Mood'
                      ? getScores()?.sentiment || 0
                      : type[2] === 'Collaboration'
                      ? getScores()?.collaboration || 0
                      : getScores()?.productivity || 0
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            xs={12}
            lg={11}
            md={11}
            sx={{
              borderRadius: 3,
              boxShadow: 3,
            }}
            // style={{ padding: "20px" }}
            id='trendlinesHolder'
          >
            <TrendlinesComponent />
          </Grid>
        </Grid>

        <style>{keyframesStyle}</style>
      </>
    </Box>
  );
};

export default EngagementDashboard;
