import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  linearProgressClasses,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { screen } from "@testing-library/react";
import { doLoadChannels, toggleChannel } from "../helpers/api";
import { useUser } from "../helpers/UserContext";
import { useChannel } from "../helpers/ChannelContext";
import { titleCase } from "../helpers/helperFunctions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { modalStyle } from "../theme/styles";
import EngagementDropdown from "./EngagementDropdown";
import { toast } from "react-toastify";
import { useTourContext } from "../helpers/TourContext";
const style = {
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "green",
    },
  },
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 45,
  borderRadius: 45,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 45,
    background: `linear-gradient(90deg, #B8921D, #71BC43 100%)`,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number
) {
  return { name, calories, fat, carbs };
}
const rows = [
  createData("Product Enineering", 159, 6.0, 24),
  createData("UI/UX & Branding", 237, 9.0, 37),
  createData("Zenith Solutions API", 262, 16.0, 24),
  createData("Web Development", 305, 3.7, 67),
  createData("Testing", 356, 16.0, 49),
];

const ManageChannels: React.FC = () => {
  const { subscriptionId, subscriptionType, slackTeamName, authToken } = useUser()

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedChannel, setSelectedChannel] = useState("select");
  const [open, setOpen] = React.useState(false);
  const [showTour, setShowTour] = useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { publicChannels, subscribedChannelsWithStats, setChannels } =
    useChannel();

  const {
    updateTourState,
    tourState: { tourActive },
  } = useTourContext();




  useEffect(() => {
    // Fetching all the puclic channels
    getSubscribedChannels();
    doLoadChannels(authToken!, "public", true).then((response) => {
      if (response.error) {
        console.log(response.error);
        setLoading(false);
      } else {
        setChannels("public", response?.data?.channels || []);
        // setLoading(false);
      }
    });
  }, []);
  const uniquePublicChannels = publicChannels
    ? publicChannels.filter(
      (publicChannel) =>
        subscribedChannelsWithStats && !subscribedChannelsWithStats.some(
          (subscribedChannel) => subscribedChannel.id === publicChannel.id
        )
    )
    : [];

  const getSubscribedChannels = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    // Fetching all the subscribed channels
    doLoadChannels(authToken!, "subscribed", true).then((response) => {
      if (response.error) {
        console.log(response.error);
        setLoading(false);
      } else {
        setChannels("subscribedWithStats", response?.data?.channels || []);
        setLoading(false);
      }
      setShowTour(true)
    });
  };

  const [data, setData] = useState([
    {
      id: 1,
      channelName: "Product Engineering",
      mood: "Positive",
      engagement: 75,
      dateAdded: "2023-08-22",
    },
    {
      id: 2,
      channelName: "UI/UX & Branding",
      mood: "Neutral",
      engagement: 50,
      dateAdded: "2023-08-23",
    },
    {
      id: 3,
      channelName: "Zenith Solutions API",
      mood: "Negative",
      engagement: 30,
      dateAdded: "2023-08-24",
    },
    {
      id: 4,
      channelName: "Product Engineering",
      mood: "Positive",
      engagement: 90,
      dateAdded: "2023-08-25",
    },
    {
      id: 5,
      channelName: "Channel E",
      mood: "Neutral",
      engagement: 60,
      dateAdded: "2023-08-26",
    },
    {
      id: 6,
      channelName: "Channel F",
      mood: "Negative",
      engagement: 20,
      dateAdded: "2023-08-27",
    },
    // Add more data entries as needed
  ]);

  const removeEntry = async (id: string) => {
    // setData(data.filter((entry) => entry.id !== id));

    const response = await toggleChannel(authToken!, id, "unlink");
    if (response.error) {
      console.log(response.error);
    } else {
      toast.success(response.data.message);
      console.log("Else", response.data.message);
      // setOpen(false)
      // getSubscribedChannels()
      const subscribedChannelsWithStatsCopy =
        JSON.parse(JSON.stringify(subscribedChannelsWithStats)) || [];
      const index = subscribedChannelsWithStatsCopy.findIndex(
        (obj: { id: string }) => obj.id === id
      );
      console.log("inside removeEntry", filteredChannels, index);
      if (index !== -1) {
        // Use splice to remove the object at the found index
        subscribedChannelsWithStatsCopy?.splice(index, 1);
        setChannels("subscribedWithStats", subscribedChannelsWithStatsCopy);
      }
      console.log("inside removeEntry", filteredChannels, index);
    }
  };

  const filteredChannels = subscribedChannelsWithStats
    ? subscribedChannelsWithStats.filter((channel) =>
      channel.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : [];
  const handleSearch = (input: any) => {
    console.log("Inside Search", input.target.value);
    setSearchQuery(input.target.value);
  };

  const linkChannel = async () => {
    const linkedChannel = publicChannels?.findIndex(
      (obj: { id: string }) => obj.id === selectedChannel
    )

    if (selectedChannel === "select") {
      toast.error("Please select a channel to link");
      return;
    }

    if ((subscribedChannelsWithStats || []).length >= 3 && subscriptionType !== "premium") {

      toast.error("You can only add 3 channels in the free plan. Please upgrade to premium to add more channels");

      return;
    }




    const response = await toggleChannel(authToken!, selectedChannel, "link");
    if (response.error) {
      console.log(response.error);
      if (JSON.parse(response.error)?.error) {
        toast.error(JSON.parse(response.error)?.error);
      }
    } else {
      // getSubscribedChannels()
      toast.success(response.data.message);
      if (linkedChannel) {
        console.log("inside linkChannel", publicChannels![linkedChannel]);
        const subscribedChannelsWithStatsCopy =
          JSON.parse(JSON.stringify(subscribedChannelsWithStats)) || [];
        subscribedChannelsWithStatsCopy.push({
          ...publicChannels![linkedChannel],
          dateAdded: new Date().toISOString(),
          stats: {
            "engagement": 0,
            "sentiment": 0,
            "collaboration": 0,
            "productivity": 0,
            "mood": "neutral"


          }
        })
        setChannels("subscribedWithStats", subscribedChannelsWithStatsCopy);
      }

      console.log("Else", response.data.message);

      setOpen(false);
    }
  };



  useEffect(() => {

    console.warn("Executing USeeffect", loading, filteredChannels, tourActive)
    if (showTour) {
      if (!loading || filteredChannels.length > 0) {
        if (tourActive) {
          window.scrollTo(0, 0)
          updateTourState({ run: true })
        }
        else {
          updateTourState({ run: false })
        }
      }
    }



  }, [showTour])


  const handleAddNewChannel = () => {
    if ((subscribedChannelsWithStats || []).length >= 3 && subscriptionType !== "premium") {

      toast.error("You can only add 3 channels in the free plan. Please upgrade to premium to add more channels");

    } else {
      handleOpen()

    }

  }



  return (
    <Box p={4}>
      <Box
        sx={{
          // minHeight: "100vh",
          // overflowY: "hidden",
          padding: 1,
          overflow: "hidden",
        }}
      >
        <Grid marginBottom={5} xs={12} lg={6}>
          <Typography
            fontSize={"40px"}
            fontWeight={"bold"}
            fontStyle={"Helvetica"}
          >
            Manage Channels
          </Typography>
          <Typography fontSize={"18px"} fontStyle={"Helvetica"}>
            View, add, or remove any Slack channels with Maxwell installed
          </Typography>
        </Grid>
        <Grid
          id="channelsListComponent"
          xs={12}
          item
          sx={{
            borderRadius: 3,
            boxShadow: "0px 3px 8px #31313140",
            //   padding: 3,
          }}
        >
          <Typography
            fontSize={"30px"}
            fontStyle={"Helvetica"}
            sx={{ padding: 3 }}
          >
            My Slack Channels ({(subscribedChannelsWithStats || []).length})
          </Typography>
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          fontSize={"14px"}
                          fontWeight={"bold"}
                          fontStyle={"Helvetica"}
                          textAlign={"left"}
                          style={{
                            marginRight: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          Channel Name
                        </Typography>
                        <Divider
                          orientation="vertical"
                          flexItem
                          style={{
                            height: "30px",
                          }}
                        />

                        <TextField
                          sx={style}
                          id="filled-search"
                          type="Searching"
                          placeholder="Search..."
                          variant="filled"
                          style={{ width: "220px", marginTop: "-15px" }}
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              backgroundColor: "transparent",
                              "&:focused": {
                                backgroundColor: "transparent",
                              },
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                              "& input": {
                                backgroundColor: "transparent",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                                "&:focus": {
                                  backgroundColor: "transparent !important",
                                },
                                "&::placeholder": {
                                  textOverflow: "ellipsis !important",
                                  color: "#00A0D5",
                                },
                              },
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon style={{ color: "#00A0D5" }} />
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            shrink: false,
                            style: {
                              transform: "translate(14px, 20px) scale(1)",
                              marginLeft: "30px",
                              marginTop: "2px",
                            },
                          }}
                          size="small"
                          onChange={handleSearch}
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontWeight: "bold" }}
                      align="right"
                    >
                      <Typography
                        fontSize={"14px"}
                        fontWeight={"bold"}
                        fontStyle={"Helvetica"}
                        textAlign={"left"}
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        Overall Mood
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontWeight: "bold" }}
                      align="right"
                    >
                      <Typography
                        fontSize={"14px"}
                        fontWeight={"bold"}
                        fontStyle={"Helvetica"}
                        textAlign={"left"}
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        Overall Engagement
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontWeight: "bold" }}
                      align="right"
                    >
                      <Typography
                        fontSize={"14px"}
                        fontWeight={"bold"}
                        fontStyle={"Helvetica"}
                        textAlign={"left"}
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        Date Added
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && filteredChannels.length == 0 ? (
                    <TableRow>
                      <StyledTableCell
                        // colSpan={4}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Skeleton height={50} count={5} />
                      </StyledTableCell>
                      <StyledTableCell
                        // colSpan={4}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Skeleton height={50} count={5} />
                      </StyledTableCell>
                      <StyledTableCell
                        // colSpan={4}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Skeleton height={50} count={5} />
                      </StyledTableCell>
                      <StyledTableCell
                        // colSpan={4}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Skeleton height={50} count={5} />
                      </StyledTableCell>
                    </TableRow>
                  ) : (
                    (filteredChannels || []).map((row) => (

                      <TableRow key={row.id}>
                        <StyledTableCell
                          width={"350px"}
                          style={{
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                          component="th"
                          scope="row"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              fontSize={"16px"}
                              fontStyle={"Helvetica"}
                            >
                              {row.name}
                            </Typography>
                            {row.id !== "DUMMY-CHANNEL" && (
                              <Button
                                onClick={() => removeEntry(row.id)}
                                startIcon={
                                  <ClearIcon
                                    style={{
                                      fontSize: "14px",
                                      marginTop: "-5px",
                                    }}
                                  />
                                }
                                variant="text"
                                color="primary"
                                style={{
                                  textTransform: "unset",
                                  color: "#B00020",
                                  fontSize: "12px",
                                }}
                                sx={{
                                  "&:hover": { backgroundColor: "transparent" },
                                }}
                              >
                                <Typography
                                  fontStyle={"Helvetica"}
                                  fontSize={"14px"}
                                  alignItems={"center"}
                                // sx={{ marginTop: "5px" }}
                                >
                                  Remove
                                </Typography>
                              </Button>
                            )}

                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            backgroundColor:
                              row?.stats?.mood === "positive"
                                ? "#f2f9f8"
                                : row?.stats?.mood === "neutral"
                                  ? "#fdf8f3"
                                  : "#fbf2f4",
                          }}
                          align="right"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color:
                                row?.stats?.mood === "positive"
                                  ? "green"
                                  : row?.stats?.mood === "neutral"
                                    ? "gray"
                                    : "red",
                            }}
                          >
                            {row?.stats?.mood === "positive" && (
                              <SentimentSatisfiedAltIcon />
                            )}
                            {row?.stats?.mood === "neutral" && (
                              <SentimentNeutralIcon />
                            )}
                            {row?.stats?.mood === "negative" && (
                              <SentimentVeryDissatisfiedIcon />
                            )}
                            <Typography
                              fontSize={"16px"}
                              fontStyle={"Helvetica"}
                              style={{ marginLeft: "10px" }}
                            >
                              {titleCase(row?.stats?.mood || "")}
                            </Typography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                          align="right"
                        >
                          <Typography
                            fontSize={"16px"}
                            fontStyle={"Helvetica"}
                            textAlign={"left"}
                          >
                            {((row?.stats?.engagement || 0) * 10).toFixed(2)}%
                          </Typography>
                          <BorderLinearProgress
                            variant="determinate"
                            value={(row?.stats?.engagement || 0) * 10}
                            sx={{
                              height: "10px",
                              width: "100%",
                              color:
                                1 < 33 ? "red" : 2 < 66 ? "yellow" : "green",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                          align="right"
                        >
                          <Typography fontSize={"16px"} fontStyle={"Helvetica"}>
                            {row.dateAdded
                              ? new Date(row.dateAdded).toDateString()
                              : ""}
                          </Typography>
                        </StyledTableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f3f3f3",
              // marginBottom: "5px",
              boxShadow: "0px -2px 6px #6F6F6F65",
            }}
          >
            <Button
              id="addChannelComponent"
              startIcon={<AddCircleOutlineIcon fontSize={"small"} />}
              variant="text"
              color="primary"
              style={{
                textTransform: "unset",
                display: "flex",
                alignItems: "center",
                color: "#00A0D5",
                //   backgroundColor: "#00A0D5",
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "center",
              }}
              sx={{ "&:hover": { backgroundColor: "transparent" } }}
              onClick={handleAddNewChannel}
            >
              <Typography
                fontStyle={"Roboto"}
                fontSize={"16px"}
                fontWeight={"bold"}
                alignItems={"center"}
                sx={{ marginTop: "5px", fontWeight: "bold" }}
              >
                Add New Channel
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Add Channel Modal"
        aria-describedby="Use to link slack channel"
      >
        <Box sx={modalStyle}>
          <Typography
            margin={"10px"}
            fontWeight={"bold"}
            textAlign={"center"}
            id="modal-add-channel"
            variant="h6"
            component="h2"
          >
            Add Channel
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Link any public channel from Slack
          </Typography>

          <FormControl sx={{ width: "100%", margin: "10px 0" }}>
            <Select
              style={{ height: "40px" }}
              defaultValue={"select"}
              onChange={(e) => setSelectedChannel(e.target.value)}
            >
              {[{ id: "select", name: "Select Channel" }]
                .concat(uniquePublicChannels || [])
                ?.map((channel) => {
                  console.log("channel", channel);

                  return (
                    <MenuItem value={channel?.id}>{channel?.name}</MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              startIcon={<AddCircleOutlineIcon fontSize={"small"} />}
              variant="text"
              color="primary"
              style={{
                textTransform: "unset",
                display: "flex",
                alignItems: "center",
                color: "#FFFFFF",
                backgroundColor: "#00A0D5",
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "center",
                marginTop: 10,
                padding: "5px 30px",
              }}
              sx={{ "&:hover": { backgroundColor: "transparent" } }}
              onClick={linkChannel}
            >
              <Typography
                fontStyle={"Roboto"}
                fontSize={"16px"}
                fontWeight={"bold"}
                alignItems={"center"}
                sx={{ marginTop: "2px", fontWeight: "bold" }}
              >
                Link Channel
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ManageChannels;
