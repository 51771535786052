import React from "react";
import { Container } from "@mui/material";
import { Box } from "@mui/system";
// import VerticalWave from '../components/VerticalWave';

interface PropType {
  component: React.FC;
}

const RegularPage: React.FC<PropType> = ({ component: Component }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#3f51b5",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <VerticalWave /> */}
      <Container maxWidth="xs">
        <Component />
      </Container>
    </Box>
  );
};

export default RegularPage;
