import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './UserContext';
import WalkthroughComponent from '../components/WalkthroughComponent';

interface PropType {
  component: React.FC;
}

const PrivateRoute: FC<PropType> = ({ component: Component }) => {
  const { validUntil, authToken, isUserEmailVerified } = useUser();
  // return <Component/>
  if (
    validUntil &&
    authToken &&
    validUntil > Date.now() &&
    isUserEmailVerified === 'true'
  )
    return (
      <>
        <Component />

        {/* <WalkthroughComponent /> */}
      </>
    );
  // if (authToken) return <Component />;
  return <Navigate to='/login' />;
};

export default PrivateRoute;
