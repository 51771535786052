import React, { useState } from 'react';
import {
  Button, TextField, Typography, Box, Link
} from '@mui/material';
import { MailOutline } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { doForgotPassword } from '../helpers/api';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordComponent: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await doForgotPassword(email);
    setIsLoading(false);

    if (response.error)
      toast.error(response.error);
    else {
      navigate('/reset-password');
      toast.success('Email sent!');
    }
  };

  return (
    <Box>
      <Box sx={{textAlign:'center'}} >
      <img src={require('../assets/full-logo.png')} width={'250px'} alt={'Logo'} />
      <Typography color={'#FFFFFF'} fontSize={'30px'} fontWeight={'bold'} style={{margin:10}} >
        Password Recovery
      </Typography>
      </Box>

    <Box
      sx={{
        backgroundColor: '#ffffff',
        padding: 3,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h5" gutterBottom align="center" fontWeight="bold">
        Forgot Your Password?
      </Typography>
      <Typography variant="body1" gutterBottom align="center">
        We will email you a link to reset your password
      </Typography>

      <Box sx={{ height: 24 }} />

      <TextField
        label="Enter Email"
        variant="outlined"
        fullWidth
        margin="normal"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        InputProps={{
          style: {
            paddingRight: '0px'
          },
          endAdornment: <MailOutline
            sx={{
              position: 'absolute',
              right: '20px',
            }} />,
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 3,
        }}
      >
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? 'Sending...' : 'Send me a link'}
        </Button>
        <Link href="/login" underline="hover">
          Need to log in?
        </Link>
      </Box>
    </Box>
    </Box>

  );
};

export default ForgotPasswordComponent;
