import React, { useState } from 'react';
import {
  Button, TextField, Typography, Box, Link
} from '@mui/material';
import { MailOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { doResetPassword } from '../helpers/api';

const SignUpComponent: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    confirmationCode: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    const response = await doResetPassword(formData.email, formData.password, formData.confirmPassword, formData.confirmationCode);
    setIsLoading(false);

    if (response.error)
      toast.error(response.error);
    else {
      navigate('/login');
      toast.success('Password reset successfully');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h5" gutterBottom align="center" fontWeight="bold">
          Reset Your Password
        </Typography>
        <TextField
          label="Enter Email"
          variant="outlined"
          fullWidth
          margin="normal"
          type="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          InputProps={{
            style: {
              paddingRight: '0px'
            },
            endAdornment: <MailOutline
              sx={{
                position: 'absolute',
                right: '20px',
              }} />,
          }}
        />
        <TextField
          label="Enter New Password"
          variant="outlined"
          fullWidth
          margin="normal"
          type={showPassword ? 'text' : 'password'}
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          InputProps={{
            style: {
              paddingRight: '0px'
            },
            endAdornment: (
              <Button
                sx={{
                  position: 'absolute',
                  right: '0px',
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </Button>
            ),
          }}
        />
        <TextField
          label="Confirm New Password"
          variant="outlined"
          fullWidth
          margin="normal"
          type={showPassword ? 'text' : 'password'}
          value={formData.confirmPassword}
          onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
          InputProps={{
            style: {
              paddingRight: '0px'
            },
            endAdornment: (
              <Button
                sx={{
                  position: 'absolute',
                  right: '0px',
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </Button>
            ),
          }}
        />
        <TextField
          label="Confirmation Code"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.confirmationCode}
          onChange={(e) => setFormData({ ...formData, confirmationCode: e.target.value })}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 3,
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? 'Updating...' : 'Reset Password'}
          </Button>
          <Typography variant="body2" align="center">
            No account?{' '}
            <Link href="/signup" underline="hover">
              Sign up &gt;
            </Link>
          </Typography>
        </Box>
      </Box>
    </form>
  );
};

export default SignUpComponent;
