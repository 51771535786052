import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Box,
  SvgIcon,
  IconButton,
} from "@mui/material";
// import Logo from "../assets/logo";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ArrowDropDown } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../helpers/UserContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { doRefreshLogin } from "../helpers/api";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useChannel } from "../helpers/ChannelContext";
import { useScores } from "../helpers/ScoreContext";
import { usePayment } from "../helpers/PaymentContext";
import { useTourContext } from "../helpers/TourContext";

const AppHeader: React.FC<{ toggleDrawer: () => void; }> = ({ toggleDrawer }) => {
  const { logout, login, refreshToken, firstName, lastName, pictureUrl } =
    useUser();
  const { clearChannels } = useChannel();
  const { clearScores } = useScores();
  const { clearPaymentDetails } = usePayment();

  const {
    updateTourState,
  } = useTourContext();


  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(
    null as HTMLButtonElement | null
  );

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the threshold as needed
      console.log("ScreenSize", isSmallScreen, window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleProfileClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const navigateToProfile = () => {
    handleProfileClose();
    navigate("/profile");
  };

  const navigateToSignIn = () => {
    handleProfileClose();
    clearChannels();
    clearScores();
    clearPaymentDetails();

    logout();
    navigate("/login");

  };

  const handleRefreshLogin = async () => {
    if (!refreshToken) return;

    const response = await doRefreshLogin(refreshToken);

    if (!response.error)
      login(
        response.data.accessToken,
        response.data.refreshToken,
        response.data.attributes
      );
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      handleRefreshLogin();
    }, 15 * 60 * 1000);

    return () => clearInterval(interval);
  });

  return (
    <AppBar position="static">
      <Toolbar sx={{ padding: "0!important", backgroundColor: "#FFFFFF" }}>
        <Box
          display="flex"
          alignItems="center"
          sx={{ width: "112.9px", backgroundColor: "#00A0D5", height: "64px" }}
        >
          {/* <Logo/> */}
          <SvgIcon
            component={Logo}
            viewBox="0 0 1144 1144"
            sx={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Box display="flex" alignItems="center" sx={{ width: "240px" }}>
          <Typography
            fontSize={{ xs: "16px", sm: '18px', md: '20px' }}
            fontWeight={"bold"}
            fontStyle={"Roboto"}
            marginLeft={2}
            sx={{ color: "#000000", fontWeight: "bold" }}
          >
            Hello, {firstName ? (firstName + "!") : "User!"}
          </Typography>
        </Box>

        <Box flexGrow={1} />
        {/* <Button
          onClick={() => {
            navigate('/dashboard/engagementDashboard')
            updateTourState({
              run: true,
              stepIndex: 0,
              tourActive: true
            })
          }}
          variant={"outlined"}
        >
          <Typography
            fontSize={"14px"}
            textTransform="none"
            fontStyle={"Robotoco"}
            sx={{ color: "#000000" }}
          >
            Help
          </Typography>
        </Button> */}

        <Button
          color="inherit"
          onClick={handleProfileClick}
          sx={{ boxSizing: `border-box` }}
        >
          <Avatar
            alt={firstName || "Maxwell"}
            src={pictureUrl ? pictureUrl : AccountCircleIcon.muiName}
          />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginLeft={1}
          >
            <Typography
              fontSize={"14px"}
              textTransform="none"
              fontStyle={"Robotoco"}
              sx={{ color: "#000000" }}
            >
              Profile
            </Typography>
          </Box>
          <KeyboardArrowDownIcon
            style={{ color: "#1A1A1A", marginTop: "-5px" }}
          />
        </Button>
        {isSmallScreen &&
          <IconButton onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        }



        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleProfileClose}
        >
          <MenuItem onClick={navigateToProfile}>Edit</MenuItem>
          <MenuItem onClick={navigateToSignIn}>Log out</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
