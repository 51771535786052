import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { headingStyles, textStyles } from "../theme/fontStyle";

interface EngagementDetailsProps {
  engagementScore: number;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 45,
  borderRadius: 45,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 45,
    background: `linear-gradient(90deg, #B8921D, #71BC43 100%)`,
  },
}));

const EngagementDetails: React.FC<EngagementDetailsProps> = ({
  engagementScore,
}) => {
  return (
    <Grid
    container
      sx={{
        // width: "100%",
        // display: "flex",
        // justifyContent: "space-between",
        // alignItems: "center",
        marginBottom: {lg:-5, md:-5},
      }}
    >
      <Grid
      xs={12}
      sm={12}
      lg={6}
      md={6}


        sx={{
          // width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Typography style={headingStyles}>Overall Engagement</Typography>
        <Typography style={textStyles} color="textPrimary">
          The result of your team's overall mood, collaboration, and
          productivity
        </Typography>
      </Grid>

      <Grid
      xs={12}
      sm={12}
      lg={6}
      md={6}

        sx={{
          marginTop:{xs:1, sm:1}
          // width: "50%",
        }}
      >
        <Box sx={{ position: "relative", flexGrow: 1 }}>
          <BorderLinearProgress
            variant="determinate"
            value={engagementScore}
            sx={{
              width: "100%",
              color:
                engagementScore < 33
                  ? "red"
                  : engagementScore < 66
                  ? "yellow"
                  : "green",
            }}
          />

          <Box
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            paddingLeft={1}
            width="100%"
            height="100%"
            top={2}
            left={10}
          >
            <Typography
              style={{ fontSize: "25px", fontWeight: "bold" }}
              color="#FFFFFF"
            >
              {engagementScore.toFixed(1)}%
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EngagementDetails;
