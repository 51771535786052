import React, { useEffect, useState } from 'react';
import {
  Typography, Box, Link
} from '@mui/material';
import { doUpdateEmailSubscription } from '../helpers/api';

const UnsubscribeEmailComponent: React.FC = () => {
  const [unsubscribeStatus, setUnsubscribeStatus] = useState('toBeExecuted');

  useEffect(() => {

    const unsubscribe = async () => {
      //extract email from url
      const url = window.location.href;
      const email = url.split('?email=')[1];
      console.log(email);

      //if email is valid, unsubscribe
      if (email) {
        const response = await doUpdateEmailSubscription(email, 'unsubscribe');
        if (response.error) {
          setUnsubscribeStatus('failed')
        }
        else {
          setUnsubscribeStatus('success');
        }
      } else {
        setUnsubscribeStatus('invalidEmail')
      }

    }

    unsubscribe();
  }, []);


  return (
    <div>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h5" gutterBottom align="center" fontWeight="bold">
          Unsubscribe
        </Typography>

        {unsubscribeStatus === 'toBeExecuted' && <Typography variant="body1" gutterBottom align="center" >
          Unsubscribing...
        </Typography>}

        {unsubscribeStatus === 'invalidEmail' && <Typography variant="body1" gutterBottom align="center" >
          Unable to unsubscribe. Please contact support.
        </Typography>}

        {unsubscribeStatus === 'failed' && <Typography variant='body1' gutterBottom align="center" >
          Failed to unsubscribe. Please contact support.
        </Typography>}


        {unsubscribeStatus === 'success' && <Typography variant="body1" gutterBottom align="center" >
          You have been successfully unsubscribed from all future emails.
        </Typography>}


      </Box>
    </div>
  );
};

export default UnsubscribeEmailComponent;
