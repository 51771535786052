import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { Attribute } from './api';

type UserState = {
  validUntil: number;
  authToken: string | null;
  refreshToken: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  pictureUrl: string | null;
  slackTeamName: string | null;
  stripeCustomerId: string | null;
  subscriptionId: string | null;
  subscriptionType: string | null;
  isUserEmailVerified: string | null;
  login: (
    authToken: string,
    refreshToken: string,
    attributes: Attribute[]
  ) => void;
  updateProfile: (attributes: Attribute[]) => void;
  logout: () => void;
};

export const UserContext = createContext<UserState>({
  validUntil: 0,
  authToken: null,
  refreshToken: null,
  email: null,
  firstName: null,
  lastName: null,
  pictureUrl: null,
  slackTeamName: null,
  stripeCustomerId: null,
  subscriptionId: null,
  subscriptionType: null,
  isUserEmailVerified: 'false',
  login: () => {},
  updateProfile: () => {},
  logout: () => {},
});

export const UserProvider = (props: PropsWithChildren) => {
  const [validUntil, setValidUntil] = useState<number>(
    parseInt(localStorage.getItem('valid_until') || '0', 10)
  );
  const [authToken, setAuthToken] = useState<string | null>(
    localStorage.getItem('auth_token')
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    localStorage.getItem('refresh_token')
  );
  const [email, setEmail] = useState<string | null>(
    localStorage.getItem('email')
  );
  const [firstName, setFirstName] = useState<string | null>(
    localStorage.getItem('first_name')
  );
  const [lastName, setLastName] = useState<string | null>(
    localStorage.getItem('last_name')
  );
  const [pictureUrl, setPictureUrl] = useState<string | null>(
    localStorage.getItem('picture_url')
  );
  const [slackTeamName, setSlackTeamName] = useState<string | null>(
    localStorage.getItem('slack_team_name')
  );
  const [stripeCustomerId, setStripeCustomerId] = useState<string | null>(
    localStorage.getItem('stripeCustomerId')
  );
  const [subscriptionId, setSubscriptionId] = useState<string | null>(
    localStorage.getItem('subscriptionId')
  );
  const [subscriptionType, setSubscriptionType] = useState<string | null>(
    localStorage.getItem('subscriptionType')
  );
  const [isUserEmailVerified, setIsUserEmailVerified] = useState<string | null>(
    localStorage.getItem('isUserEmailVerified')
  );
  const login = (
    authToken: string,
    refreshToken: string,
    attributes: Attribute[]
  ) => {
    console.log('login is called');

    localStorage.setItem(
      'valid_until',
      (Date.now() + 1000 * 60 * 60).toString()
    );
    localStorage.setItem('auth_token', authToken);
    localStorage.setItem('refresh_token', refreshToken);
    localStorage.setItem(
      'email',
      attributes.find((attribute) => attribute.Name === 'email')!.Value
    );

    const slackTeamName = attributes.find(
      (attribute) => attribute.Name === 'custom:slackTeamName'
    )!.Value;
    if (slackTeamName && slackTeamName !== 'none') {
      localStorage.setItem(
        'slack_team_name',
        attributes.find(
          (attribute) => attribute.Name === 'custom:slackTeamName'
        )!.Value
      );
    }

    const isUserEmailVerified = attributes.find(
      (attribute) => attribute.Name === 'custom:isUserEmailVerified'
    )?.Value;
    console.log('isUserEmailVerified ->', isUserEmailVerified);
    if (isUserEmailVerified) {
      localStorage.setItem(
        'isUserEmailVerified',
        attributes.find(
          (attribute) => attribute.Name === 'custom:isUserEmailVerified'
        )!.Value
      );
    } else {
      localStorage.removeItem('isUserEmailVerified');
    }

    const subscriptionId = attributes.find(
      (attribute) => attribute.Name === 'custom:subscriptionId'
    )?.Value;
    if (subscriptionId && subscriptionId !== 'none') {
      localStorage.setItem(
        'subscriptionId',
        attributes.find(
          (attribute) => attribute.Name === 'custom:subscriptionId'
        )!.Value
      );
    } else {
      localStorage.removeItem('subscriptionId');
    }

    const subscriptionType = attributes.find(
      (attribute) => attribute.Name === 'custom:subscriptionType'
    )?.Value;
    if (subscriptionType && subscriptionType !== 'none') {
      localStorage.setItem(
        'subscriptionType',
        attributes.find(
          (attribute) => attribute.Name === 'custom:subscriptionType'
        )!.Value
      );
    } else {
      localStorage.removeItem('subscriptionType');
    }

    const stripeCustomerId = attributes.find(
      (attribute) => attribute.Name === 'custom:stripeCustomerId'
    )?.Value;
    if (stripeCustomerId && stripeCustomerId !== 'none') {
      localStorage.setItem(
        'stripeCustomerId',
        attributes.find(
          (attribute) => attribute.Name === 'custom:stripeCustomerId'
        )!.Value
      );
    } else {
      localStorage.removeItem('stripeCustomerId');
    }

    setValidUntil(Date.now() + 1000 * 60 * 60);
    setAuthToken(authToken);
    setRefreshToken(refreshToken);
    updateProfile(attributes);
  };

  const updateProfile = (attributes: Attribute[]) => {
    attributes.forEach((attribute) => {
      switch (attribute.Name) {
        case 'email':
          localStorage.setItem('email', attribute.Value);
          setEmail(attribute.Value);
          break;
        case 'given_name':
          localStorage.setItem('first_name', attribute.Value);
          setFirstName(attribute.Value);
          break;
        case 'family_name':
          localStorage.setItem('last_name', attribute.Value);
          setLastName(attribute.Value);
          break;
        case 'picture':
          localStorage.setItem('picture_url', attribute.Value);
          setPictureUrl(attribute.Value);
          break;
        case 'custom:slackTeamName':
          if (attribute.Value === 'none') {
            localStorage.removeItem('slack_team_name');
            setSlackTeamName(null);
            break;
          }
          localStorage.setItem('slack_team_name', attribute.Value);
          setSlackTeamName(attribute.Value);
          break;
        case 'custom:stripeCustomerId':
          if (attribute.Value === 'none') {
            localStorage.removeItem('stripeCustomerId');
            setStripeCustomerId(null);
            break;
          }
          localStorage.setItem('stripeCustomerId', attribute.Value);
          setStripeCustomerId(attribute.Value);
          break;
        case 'custom:subscriptionId':
          if (attribute.Value === 'none') {
            localStorage.removeItem('subscriptionId');
            setSubscriptionId(null);
            break;
          }
          localStorage.setItem('subscriptionId', attribute.Value);
          setSubscriptionId(attribute.Value);
          break;
        case 'custom:subscriptionType':
          if (attribute.Value === 'none') {
            localStorage.removeItem('subscriptionType');
            setSubscriptionType(null);
            break;
          }
          localStorage.setItem('subscriptionType', attribute.Value);
          setSubscriptionType(attribute.Value);
          break;
        case 'custom:isUserEmailVerified':
          if (attribute.Value === 'none') {
            localStorage.removeItem('isUserEmailVerified');
            setIsUserEmailVerified(null);
            break;
          }
          localStorage.setItem('isUserEmailVerified', attribute.Value);
          setIsUserEmailVerified(attribute.Value);
          break;
        default:
          break;
      }
    });
  };

  const logout = () => {
    console.log('logout is called');
    // Clear all context data

    localStorage.removeItem('valid_until');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('email');
    localStorage.removeItem('first_name');
    localStorage.removeItem('last_name');
    localStorage.removeItem('picture_url');
    localStorage.removeItem('slack_team_name');
    localStorage.removeItem('slackAccessToken');
    localStorage.removeItem('slackTimeRange');
    localStorage.removeItem('stripeCustomerId');
    localStorage.removeItem('subscriptionId');
    localStorage.removeItem('subscriptionType');
    localStorage.removeItem('isUserEmailVerified');

    // Reset state variables
    setValidUntil(0);
    setAuthToken(null);
    setRefreshToken(null);
    setEmail(null);
    setFirstName(null);
    setLastName(null);
    setPictureUrl(null);
    setSlackTeamName(null);
    setStripeCustomerId(null);
    setSubscriptionId(null);
    setSubscriptionType(null);
    setIsUserEmailVerified('false');
  };

  return (
    <UserContext.Provider
      value={{
        validUntil,
        authToken,
        refreshToken,
        email,
        firstName,
        lastName,
        pictureUrl,
        slackTeamName,
        stripeCustomerId,
        subscriptionId,
        subscriptionType,
        isUserEmailVerified,
        login,
        updateProfile,
        logout,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserState => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
