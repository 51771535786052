import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";

interface DurationDropdownProps {
  onChange: (channel: SelectChangeEvent) => void;
}


const DurationDropdown: React.FC<DurationDropdownProps> = ({
  onChange,
}) => {


  const durations = [
    { id: '3month', name: 'Past 3 Month' },
    { id: '6month', name: 'Past 6 Month' },
    { id: '12month', name: 'Past 12 Month' },
    { id: '18month', name: 'Past 18 Month' },
  ]
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select style={{ height: "40px" }} defaultValue={"3month"} onChange={onChange} >
        {durations.map((duratiom) => {
          return <MenuItem value={duratiom?.id}>{duratiom?.name}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default DurationDropdown;
