import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Attribute, changeDefaultPaymentMethod, deletePaymentMethodApi, getPaymentMethods } from './api';
import { useUser } from './UserContext';

type PaymentState = {
  stripeCustomerId: string | null;
  subscriptionId: string | null;
  subscriptionType: string | null;
  paymentMethods: any[] | null;
  customer: any | null;
  paymentLink: string | null;
  paymentLinkData: any | null;
  paymentLinkAmount: number | null;
  clearPaymentDetails: () => void;
  fetchPaymentMethods: (accessToken: string) => void;
  deletePaymentMethod: (accessToken: string, paymentMethodId: string) => void;
  updateDefaultPaymentMethod: (accessToken: string, paymentMethodId: string) => void;
  addNewPaymentMethod: (paymentMethod: any) => void;
  addPaymentLink: (paymentLink: string) => void;
};

export const PaymentContext = createContext<PaymentState>({
  stripeCustomerId: null,
  subscriptionId: null,
  subscriptionType: null,
  paymentMethods: null,
  customer: null,
  paymentLink: null,
  paymentLinkData: null,
  paymentLinkAmount: null,
  clearPaymentDetails: () => { },
  fetchPaymentMethods: () => { },
  deletePaymentMethod: () => { },
  updateDefaultPaymentMethod: () => { },
  addNewPaymentMethod: () => { },
  addPaymentLink: () => { },
});

export const PaymentProvider = (props: PropsWithChildren) => {
  const [stripeCustomerId, setStripeCustomerId] = useState<string | null>(localStorage.getItem('stripeCustomerId'));
  const [subscriptionId, setSubscriptionId] = useState<string | null>(localStorage.getItem('subscriptionId'));
  const [subscriptionType, setSubscriptionType] = useState<string | null>(localStorage.getItem('subscriptionType'));
  const [paymentMethods, setPaymentMethods] = useState<any[] | null>(null);
  const [customer, setCustomer] = useState<any | null>(null);
  const [paymentLink, setPaymentLink] = useState<string | null>(null);
  const [paymentLinkData, setPaymentLinkData] = useState<any | null>(null);
  const [paymentLinkAmount, setPaymentLinkAmount] = useState<number | null>(null);

  const clearPaymentDetails = () => {
    console.log("clearPaymentDetails Called");

    setPaymentMethods(null);
  };

  const fetchPaymentMethods = async (accessToken: string) => {
    const response = await getPaymentMethods(accessToken);
    if (response) {
      console.log("PaymentMethods", response?.data);
      setPaymentMethods(response?.data?.data || null)
      setCustomer(response?.data?.customer || null)
    }
  }


  const deletePaymentMethod = async (accessToken: string, paymentMethodId: string) => {
    const response = await deletePaymentMethodApi(accessToken, paymentMethodId);
    if (response) {
      //filter out the deleted payment method from paymentMethods
      const updatedPaymentMethods = paymentMethods?.filter((paymentMethod) => paymentMethod.id !== paymentMethodId);
      setPaymentMethods(updatedPaymentMethods || null);
    }
  }

  const updateDefaultPaymentMethod = async (accessToken: string, paymentMethodId: string) => {
    const response = await changeDefaultPaymentMethod(accessToken, paymentMethodId);
    console.log("updateDefaultPaymentMethod", response);
    if (response) {
      //modify default payment method for the customer
      let currentCustomer = JSON.parse(JSON.stringify(customer));
      currentCustomer.invoice_settings.default_payment_method = paymentMethodId;
      setCustomer(currentCustomer);
    }
  }

  const addNewPaymentMethod = async (paymentMethod: any) => {
    //add new payment method to the top of existing list of payment methods
    const updatedPaymentMethods = paymentMethods ? [paymentMethod, ...paymentMethods] : [paymentMethod];
    setPaymentMethods(updatedPaymentMethods);

    //modify default payment method for the customer
    let currentCustomer = JSON.parse(JSON.stringify(customer));
    currentCustomer.invoice_settings.default_payment_method = paymentMethod.id;
    setCustomer(currentCustomer);
  }

  const addPaymentLink = (paymentLink: any) => {
    console.log("setPaymentLink Called");

    setPaymentLink(paymentLink?.url);
    setPaymentLinkData(paymentLink);
    console.warn("paymentLink", paymentLink);
    if (paymentLink) {
      let lineItems = paymentLink?.line_items;
      let amount = 0;
      lineItems.forEach((item: any) => {
        amount += item.amount_total;
      });
      amount = parseFloat((amount / 100).toFixed(2));
      if (amount) {
        setPaymentLinkAmount(amount);
      }
    }
  }


  return (
    <PaymentContext.Provider value={{
      stripeCustomerId,
      subscriptionId,
      subscriptionType,
      paymentMethods,
      customer,
      paymentLink,
      paymentLinkData,
      paymentLinkAmount,
      clearPaymentDetails,
      fetchPaymentMethods,
      deletePaymentMethod,
      updateDefaultPaymentMethod,
      addNewPaymentMethod,
      addPaymentLink,
    }}>
      {props.children}
    </PaymentContext.Provider>
  );
};

export const usePayment = (): PaymentState => {
  const context = useContext(PaymentContext);
  if (context === undefined) {
    throw new Error('usePayment must be used within a PaymentProvider');
  }
  return context;
};
