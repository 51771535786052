import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { primary } from "../theme/colors";
import { downloadReport } from "../helpers/api";
import { useUser } from "../helpers/UserContext";
import { toast } from "react-toastify";
import { useTourContext } from "../helpers/TourContext";
import RestrictedAreaComponent from "./RestrictedAreaComponent";
const ManageReporting: React.FC = () => {
  const { authToken } = useUser();
  const [loader, setLoader] = useState(false);
  const [layoutLoader, setLayoutLoader] = useState(false);
  const {
    updateTourState,
    tourState: { tourActive },
  } = useTourContext();

  const storedButtons = localStorage.getItem("buttonPriority");
  const initialButtons = storedButtons
    ? JSON.parse(storedButtons)
    : ["Overall Mood", "Collaboration", "Productivity"];

  const [buttons, setButtons] = useState(initialButtons);

  const [selectedOption, setSelectedOption] = useState("week"); // Initial selected option

  useEffect(() => {
    const storedButtons = localStorage.getItem("buttonPriority");
    if (storedButtons) {
      setButtons(JSON.parse(storedButtons));
      console.log(storedButtons);
    }

    if (tourActive) {
      window.scrollTo(0, 0)
      updateTourState({ run: true })
    }


  }, []);

  // Save button priority positions to local storage whenever they change
  // useEffect(() => {
  //   localStorage.setItem("buttonPriority", JSON.stringify(buttons));
  // }, [buttons]);

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
  };

  const moveButton = (index: number, direction: "up" | "down") => {
    const newButtons = [...buttons];
    const newIndex = direction === "up" ? index - 1 : index + 1;
    if (newIndex >= 0 && newIndex < buttons.length) {
      [newButtons[index], newButtons[newIndex]] = [
        newButtons[newIndex],
        newButtons[index],
      ];
      setButtons(newButtons);
    }
  };
  const saveAndUpdate = () => {
    if (layoutLoader) {
      return
    }
    setLayoutLoader(true)
    localStorage.setItem("buttonPriority", JSON.stringify(buttons));

    setTimeout(() => {
      setLayoutLoader(false)
    }, 3000)
  }

  const resetToDefault = () => {
    console.log("resetToDefault");
    localStorage.setItem("buttonPriority", JSON.stringify(["Overall Mood", "Collaboration", "Productivity"]));
    setButtons(["Overall Mood", "Collaboration", "Productivity"])

  }
  const handleDownloadReport = async () => {
    if (loader) {
      return;
    }
    setLoader(true);
    console.log("handleDownloadReport Pressed", selectedOption);
    const response = await downloadReport(authToken!, selectedOption);
    if (response.error) {
      console.error("response", response);
      toast.error(response.error);
      setLoader(false);
    } else {
      console.log("response", response?.data);
      const reportURL = response?.data?.report;
      if (reportURL) {
        console.log("reportURL", reportURL.url);
        setLoader(false);
        window.open(reportURL.url, "_blank");
      }
    }
  };
  const timePeriodOptions = [
    {
      id: "week",
      name: "Past week",
    },
    {
      id: "month",
      name: "Past Month",
    },
    {
      id: "quarter",
      name: "Past 3 Months",
    },
  ];
  return (
    <Box p={4}>

      <Box
        sx={{
          padding: 1,
        }}
      >
        <Grid marginBottom={5} xs={12} sm={12} md={8} lg={6}>
          <Typography
            fontSize={"40px"}
            fontWeight={"bold"}
            fontStyle={"Helvetica"}
          >
            Manage Reporting
          </Typography>
          <Typography fontSize={"18px"} fontStyle={"Helvetica"}>
            Use the below options to manage the frequency and priority of the
            reporting displayed on your engagement dashboard.
          </Typography>
        </Grid>

        <Grid container spacing={3} xs={12} sm={12} md={8} lg={6}>
          {/* Download Report */}
          <Grid
            id="downloadReportContainer"
            item
            marginBottom={5}
            xs={12}
            sx={{
              borderRadius: 3,
              boxShadow: 3,
              marginLeft: "24px",
              padding: 5,
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ paddingBottom: { xs: "10px", md: "0px" } }}
                marginBottom={1}
              >
                <Typography fontSize={"30px"} fontStyle={"Helvetica"}>
                  Download Report
                </Typography>
                <Typography
                  fontSize={"16px"}
                  fontStyle={"Helvetica"}
                  color="textPrimary"
                >
                  Choose the timeline you'd like us to pull the report from
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: { xs: "row", sm: "row" },
                  }}
                >
                  {timePeriodOptions.map((option) => (
                    <Box
                      key={option.id}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 2,
                      }}
                    >
                      <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                        color="primary"
                        style={{ color: "#00A0D5" }}
                        checked={selectedOption === option.id}
                        onClick={() => handleOptionSelect(option.id)}
                      />
                      <Typography
                        fontSize={"14px"}
                        fontStyle={"Helvetica"}
                        color={"#00A0D5"}
                      >
                        {option.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>

                <Button
                  onClick={handleDownloadReport}
                  disabled={loader}
                  variant="outlined"
                  color="primary"
                  style={{ color: "#00A0D5", textTransform: "unset" }}
                  startIcon={!loader && <DownloadIcon />}
                  sx={{ marginTop: "10px", width: '200px' }}
                >
                  {loader ? (
                    <CircularProgress
                      size={"20px"}
                      style={{ color: primary.logoColor, padding: 4 }}
                    />
                  ) : (
                    <Typography
                      fontSize={"15px"}
                      fontWeight={"bold"}
                      fontStyle={"Helvetica"}
                      style={{ marginTop: "5px" }}
                    >
                      Download Report
                    </Typography>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Customized Dashboard View */}
          <Grid
            id="reorderDashboardViewComponent"
            item
            xs={12}
            sx={{
              borderRadius: 3,
              boxShadow: 3,
              marginLeft: "24px",
              padding: 5,
            }}
          >
            <Typography fontSize={"30px"} fontStyle={"Helvetica"}>
              Customized Dashboard View
            </Typography>
            <Typography
              fontSize={"16px"}
              fontStyle={"Helvetica"}
              color="textPrimary"
            >
              Click or tap the blue arrows below in "My Layout" to customize the
              reporting view displayed on your engagement dashboard
            </Typography>
            <Grid marginTop={1}>
              <Typography
                fontSize={"16px"}
                fontWeight={"bold"}
                fontStyle={"Helvetica"}
              >
                My Layout
              </Typography>
            </Grid>

            <Grid container spacing={1}>
              {buttons?.map((buttonText: string, index: number) => (
                <Grid item xs={12} key={index}>
                  <Grid
                    container
                    alignItems="center"
                    sx={{
                      marginTop: "10px",
                      width: "200px",
                      border: "1px solid #000",
                      borderRadius: "5px",
                      padding: "5px 10px",
                      borderColor: "#00A0D5",
                    }}
                  >
                    <Grid item xs={2}>
                      <IconButton
                        disabled={index === buttons.length - 1}
                        edge="start"
                        aria-label="move-down"
                        style={{ color: "#00A0D5" }}
                        onClick={() => moveButton(index, "down")}
                      >
                        <ArrowDownwardIcon
                          style={{
                            opacity: index === buttons.length - 1 ? 0 : 1,
                          }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item xs={8} textAlign="center">
                      <Typography fontSize={"15px"} fontStyle={"Helvetica"}>
                        {buttonText}
                      </Typography>
                      {index === 0 && (
                        <Typography
                          fontSize={"12px"}
                          fontWeight={"bold"}
                          fontStyle={"Helvetica"}
                          color="black"
                          textTransform={"uppercase"}
                          sx={{ fontWeight: "bold", fontSize: 11 }}
                        >
                          Most Important
                        </Typography>
                      )}
                      {index === 1 && (
                        <Typography
                          variant="body2"
                          color="black"
                          textTransform={"uppercase"}
                          sx={{ fontWeight: "bold", fontSize: 11 }}
                        >
                          Important
                        </Typography>
                      )}
                      {index === 2 && (
                        <Typography
                          variant="body2"
                          color="black"
                          textTransform={"uppercase"}
                          sx={{ fontWeight: "bold", fontSize: 11 }}
                        >
                          Least Important
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        disabled={index === 0}
                        edge="end"
                        aria-label="move-up"
                        style={{ color: "#00A0D5" }}
                        onClick={() => moveButton(index, "up")}
                      >
                        <ArrowUpwardIcon
                          style={{
                            opacity: index === 0 ? 0 : 1,
                          }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "10px",
                flexWrap: "wrap",
              }}
            >
              {layoutLoader ?
                <Box>
                  <Button
                    startIcon={<CheckCircleIcon fontSize={"small"} />}
                    variant="text"
                    color="primary"
                    style={{
                      textTransform: "unset",
                      display: "flex",
                      alignItems: "center",
                      color: "#00856F",
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                    sx={{ marginTop: "10px" }}
                  >
                    <Typography
                      fontSize={"15px"}
                      fontStyle={"Helvetica"}
                      alignItems={"center"}
                      marginTop={'7px'}
                      fontWeight={'bold'}
                    >
                      Dashboard Reporting Updated
                    </Typography>
                  </Button>
                </Box> :
                <>
                  <Box>
                    <Button
                      onClick={saveAndUpdate}
                      variant="contained"
                      color="primary"
                      style={{
                        color: "white",
                        backgroundColor: "#00A0D5",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                      sx={{ marginTop: "10px" }}
                    >
                      <Typography marginTop={'5px'} textTransform={'capitalize'} fontSize={"15px"} fontStyle={"Helvetica"}>
                        Save & Update Dashboard
                      </Typography>
                    </Button>
                  </Box>
                  <Box sx={{ marginLeft: "20px" }}>
                    <Button
                      onClick={resetToDefault}
                      startIcon={<RestartAltIcon fontSize={"small"} />}
                      variant="text"
                      color="primary"
                      style={{
                        textTransform: "unset",
                        display: "flex",
                        alignItems: "center",
                        color: "#00A0D5",
                        //   backgroundColor: "#00A0D5",
                        fontSize: 16,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      sx={{ marginTop: "10px" }}
                    >
                      <Typography
                        fontSize={"15px"}
                        fontStyle={"Helvetica"}
                        alignItems={"center"}
                        sx={{ textDecoration: "underline" }}
                      >
                        Reset to default layout
                      </Typography>
                    </Button>
                  </Box>
                </>
              }


            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <RestrictedAreaComponent /> */}
    </Box>
  );
};

export default ManageReporting;
