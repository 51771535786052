import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { getChannels } from '../helpers/slackApi';
import { useChannel } from "../helpers/ChannelContext";

interface EngagementDropdownProps {
  onChange: (channel: SelectChangeEvent) => void;
}


const EngagementDropdown: React.FC<EngagementDropdownProps> = ({
  onChange,
}) => {

  const {
    subscribedChannels,
  } = useChannel();


  const channels = [{ id: 'all', name: 'All Channels' }].concat(subscribedChannels || []);
  // const channels = ["All Channels"];
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select style={{ height: "40px" }} defaultValue={"all"} onChange={onChange} >
        {channels.map((channel) => {
          return <MenuItem value={channel?.id}>{channel?.name}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default EngagementDropdown;
