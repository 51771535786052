import React from "react";
import { pxToVw } from "../constants/utils";

// xs <  768px
// sm => 768px
// md => 992px
// lg => 1200px
const totalViewportWidth = window.innerWidth;
const fontSizeInPx = 20;
const fontSizeInVw = pxToVw(fontSizeInPx, totalViewportWidth);

console.log("totalViewportWidth", totalViewportWidth, fontSizeInVw);

export const headingStyles = {
  fontSize: "20px",
  color: "#1A1A1A",
  fontFamily: "Helvetica",
};

export const textStyles = {
  fontSize: "14px",
  color: "#414141",
};
