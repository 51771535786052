import { Typography } from '@mui/material';
import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';
import { Step } from 'react-joyride';

type TourSchema = {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
}

type TourState = {
  tourState: TourSchema;
  updateTourState: (state: any) => void;
}


const appState = {
  run: false,
  stepIndex: 0,
  steps: [

    //Engagement Dashboard //0
    {
      target: '#channelList',
      content: (
        <>
          <Typography variant='h6'>
            Channel Selection
          </Typography>
          <Typography>
            You can select from the list of all the channels you have subscribed to.
          </Typography>
        </>
      ),
      disableBeacon: true,
      // disableScrolling: true,
    },
    //1
    {
      target: '#durationTab',
      content: (
        <>
          <Typography variant='h6'>
            Duration Selection
          </Typography>
          <Typography>
            You can easily switch between the duration of the data you want to see.
          </Typography>
        </>
      ),
      disableBeacon: true,
      // disableScrolling: true,

    },
    //2
    {
      target: '#engagementScoreHolder',
      content: (
        <>
          <Typography variant='h6'>
            Engagement Score Summary
          </Typography>
          <Typography>
            Our formula calculates based on the conversation volume, sentiment, and the number of people involved in the conversation.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    //3
    {
      target: '#categoryScoreHolder',
      content: (
        <>
          <Typography variant='h6'>
            Scores Breakdown
          </Typography>
          <Typography>
            With a quick glance, you can see the breakdown of the scores based on collaboration, sentiment, and productivity.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    //Conversation Breakdown //4
    {
      target: '#coversationMoodContainer',
      content: (
        <>
          <Typography variant='h6'>
            Conversation Mood Summary
          </Typography>
          <Typography>
            You can see the overall mood of the conversations based on the sentiment analysis.
          </Typography>
        </>
      ),
      disableBeacon: true,
      // disableScrolling: true,

    },
    //5
    {
      target: '#trendingWordsContainer',
      content: (
        <>
          <Typography variant='h6'>
            Trending Words
          </Typography>
          <Typography>
            You can see the most frequently used words in the conversation.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    //6
    {
      target: '#conversationAnalysisContainer',
      content: (
        <>
          <Typography variant='h6'>
            Conversation Analysis
          </Typography>
          <Typography>
            You can see the sentences using the most frequently used words in the conversation.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    // Report Page //7
    {
      target: '#downloadReportContainer',
      content: (
        <>
          <Typography variant='h6'>
            Download Reports
          </Typography>
          <Typography>
            With a single click, you can download the weekly,monthly or quarterly reports.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    //8
    {
      target: '#reorderDashboardViewComponent',
      content: (
        <>
          <Typography variant='h6'>
            Reorder Dashboard View
          </Typography>
          <Typography>
            Don't like how the dashboard looks? You can easily reorder the dashboard view.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    // Manage Channels //9
    {
      target: '#channelsListComponent',
      content: (
        <>
          <Typography variant='h6'>
            Channels Lists
          </Typography>
          <Typography>
            You can see the list of all the channels Maxwell is subscribed to.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    //10
    {
      target: '#addChannelComponent',
      content: (
        <>
          <Typography variant='h6'>
            Add New Channel
          </Typography>
          <Typography>
            Don't see the channel you want Maxwell to analyze? You can add a new channel.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    //11
    {
      target: '#profilePictureHolder',
      content: (
        <>
          <Typography variant='h6'>
            Update Profile Picture
          </Typography>
          <Typography>
            Your profile picture is the first thing people see when they interact with you. Make sure you have a good one!
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    //12
    {
      target: '#contactInfoHolder',
      content: (
        <>
          <Typography variant='h6'>
            Update Profile Details
          </Typography>
          <Typography>
            You can update your profile details here.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    //13
    {
      target: '#connectionsHolder',
      content: (
        <>
          <Typography variant='h6'>
            Integration Details
          </Typography>
          <Typography>
            You can see the list of all the integrations you have connected to Maxwell. You can also add new integrations here.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
    //14
    {
      target: '#paymentComponent',
      content: (
        <>
          <Typography variant='h6'>
            Buy Subscription
          </Typography>
          <Typography>
            To use Maxwell to its full potential, you need to buy a subscription. Click the purchase subscription button to get started.
          </Typography>
        </>
      ),
      disableBeacon: true,
    },
  ],
  tourActive: false,
};

export const TourContext = createContext<TourState>({
  tourState: appState,
  updateTourState: () => { },
});
TourContext.displayName = 'TourContext';

export function TourProvider(props: PropsWithChildren) {
  const [tourState, setTourState] = useState(appState);

  const updateTourState = (newState: any) => {
    console.warn("Update tour state called with ", newState)
    setTourState({ ...tourState, ...newState })
  }


  return (
    <TourContext.Provider value={{ tourState, updateTourState }} {...props} >
      {props.children}
    </TourContext.Provider>
  )
}

export const useTourContext = (): TourState => {
  const context = useContext(TourContext);

  if (!context) {
    throw new Error('useTourContext must be used within a TourProvider');
  }

  return context;
}
