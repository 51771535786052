import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { Attribute } from './api';

type ChannelSchema = {
  id: string;
  name: string;
  stats?: {
    engagement: number;
    sentiment: number;
    collaboration: number;
    productivity: number;
    mood: string;
  },
  dateAdded?: string;
}[]

type ChannelState = {
  publicChannels: ChannelSchema | null;
  subscribedChannels: ChannelSchema | null;
  subscribedChannelsWithStats: ChannelSchema | null;
  setChannels: (type: string, channels: ChannelSchema) => void;
  clearChannels: () => void;
};

export const ChannelContext = createContext<ChannelState>({
  publicChannels: null,
  subscribedChannels: null,
  subscribedChannelsWithStats: null,
  setChannels: () => { },
  clearChannels: () => { },
});

export const ChannelProvider = (props: PropsWithChildren) => {
  const [publicChannels, setPublicChannels] = useState<ChannelSchema | null>(null);
  const [subscribedChannels, setSubscribedChannels] = useState<ChannelSchema | null>(null);
  const [subscribedChannelsWithStats, setSubscribedChannelsWithStats] = useState<ChannelSchema | null>(null);

  const setChannels = (type: string, channels: ChannelSchema) => {
    switch (type) {
      case 'public':
        setPublicChannels(channels.map((channel: any) => {
          if (channel.name.startsWith("#")) {
            return channel
          }
          return {
            id: channel.id,
            name: "#" + channel.name
          }
        }));
        break;
      case 'subscribed':
        setSubscribedChannels(channels.map((channel: any) => {
          if (channel.name.startsWith("#")) {
            return channel
          }
          return {
            id: channel.id,
            name: "#" + channel.name
          }
        }));
        break;
      case 'subscribedWithStats':
        setSubscribedChannelsWithStats(channels.map((channel: any) => {
          if (channel.name.startsWith("#")) {
            return channel
          }
          return {
            ...channel,
            name: "#" + channel.name,
          }
        }
        ));
    }
  }
  const clearChannels = () => {
    // Clear all channel dataa
    console.log("ClearChannels Called");

    setPublicChannels(null);
    setSubscribedChannels(null);
    setSubscribedChannelsWithStats(null);
  }
  return (
    <ChannelContext.Provider value={{ publicChannels, subscribedChannels, subscribedChannelsWithStats, setChannels, clearChannels }}>
      {props.children}
    </ChannelContext.Provider>
  );
};

export const useChannel = (): ChannelState => {
  const context = useContext(ChannelContext);
  if (context === undefined) {
    throw new Error('useChannel must be used within a channelProvider');
  }
  return context;
};
