import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundComponent: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        padding: 3,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h5" gutterBottom align="center" fontWeight="bold">
        404 - Page Not Found
      </Typography>
      <Typography variant="body1" gutterBottom align="center">
        Oops! The page you're looking for does not exist.
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        fullWidth 
        sx={{ marginTop: 3 }} 
        onClick={() => navigate('/login')}
      >
        Go to Log in
      </Button>
    </Box>
  );
};

export default NotFoundComponent;
