import { Typography, Box, SvgIcon } from "@mui/material";
import { ReactComponent as Circle } from "../assets/POSITIVE Orb.svg";
import { useUser } from "../helpers/UserContext";

const borderRadius = 5;
const negativeColor = "#B00020";
const negativeText = "NEGATIVE";
const neutralColor = "#DB7D0A";
const neutralText = "NEUTRAL";
const positiveColor = "#00856F";
const positiveColorBright = "#6FBD44";
const positiveText = "POSITIVE";
const disabledColor = "#757575";

interface ScoreModalProps {
  title: string;
  score: number;
}

const getScoreType = (score: number) => {
  if (score >= 0 && score < 4) return 0;
  else if (score >= 4 && score <= 7) return 1;
  return 2;
};

const getBoxBackgroundColor = (score: number) => {
  let scoreType = getScoreType(score);
  // if (scoreType == 2) return positiveColor;
  return "FFFFFF";
};

const getTopTextColor = (score: number) => {
  let scoreType = getScoreType(score);
  // if (scoreType == 2) return "#FFFFFF";
  return "#000000";
};



const getCircleBorder = (score: number) => {
  let scoreType = getScoreType(score);
  if (scoreType == 0 || scoreType == 1) return 8;
  return 8;
};


const getCircleGlow = (score: number) => {
  let scoreType = getScoreType(score);
  // if (scoreType == 2) return `0 0px 50px ${positiveColorBright}`;
  return "";
};

const ScoreModal: React.FC<ScoreModalProps> = ({ title, score }) => {

  const { subscriptionType, subscriptionId } = useUser()



  const getCircleColor = (score: number) => {

    if (!subscriptionId || subscriptionId === "none" || !subscriptionType || subscriptionType === "none" || subscriptionType === "basic") {
      return disabledColor
    }
    let scoreType = getScoreType(score);
    if (scoreType == 0) return negativeColor;
    else if (scoreType == 1) return neutralColor;
    return positiveColor;
  };


  const getSubTextColor = (score: number) => {
    if (!subscriptionId || subscriptionId === "none" || !subscriptionType || subscriptionType === "none" || subscriptionType === "basic") {
      return disabledColor
    }
    let scoreType = getScoreType(score);
    if (scoreType == 0) return negativeColor;
    else if (scoreType == 1) return neutralColor;
    else return positiveColor;
  };






  let borderGradient =
    "transparent linear-gradient(290deg, #6FBD44 0%, #E5A233 80%, #B00020 100%) 0% 0% no-repeat padding-box";

  if (!subscriptionId || subscriptionId === "none" || !subscriptionType || subscriptionType === "none" || subscriptionType === "basic") {
    borderGradient = "#6A6A6A 0% 0% no-repeat padding-box";
  }


  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderRadius={'10px 10px 10px 10px'}
      borderTop={`10px solid ${borderGradient}`}
      borderColor={'background: transparent linear-gradient(290deg, #6FBD44 0%, #E5A233 80%, #B00020 100%) 0% 0% no-repeat padding-box;'}
      paddingBottom={8}
      sx={{
        backgroundColor: getBoxBackgroundColor(score),
        boxShadow: "0px 3px 8px #31313140",
        height: "350px",
      }}
    >

      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "10px",
            marginTop: '0px',
            marginBottom: '10px',
            background: borderGradient,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            position: 'relative',
          }}
        >
          {/* <div
        style={{
          width: "100%",
          height: "10px",
          marginTop:'15px',
          marginBottom:'10px',
          background: 'white',
          position:'absolute',
          borderTopLeftRadius: '2px',
          borderTopRightRadius: '2px',

        }}
        >
        </div> */}
        </div>

        <Box
          sx={{
            width: "100%",
            height: "10%",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            sx={{ color: getTopTextColor(score), fontStyle: "Helvetica" }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "10%",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            align="center"
            sx={{
              color: getSubTextColor(score),
              marginTop: "20px",
              fontStyle: "Helvetica",
            }}
          >
            {getScoreType(score) == 2
              ? positiveText
              : getScoreType(score) == 1
                ? neutralText
                : negativeText}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >

          <Box
            sx={{
              width: "100%",
              // height:'90%',
              // width:'90%',
              maxWidth: "250px",
              paddingBottom: "min(100%, 250px)",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                height: '231px',
                width: '231px',
                top: '15px',
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: "50%",
                border: `${getCircleBorder(score)}px solid ${getCircleColor(
                  score
                )}`,
                boxSizing: "border-box",
                boxShadow: getCircleGlow(score),
                containerName: "fontScaler",
              }}
            >
              <Box
                textAlign="center"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -55%)",
                }}
              >
                <Typography
                  color={getSubTextColor(score)}
                  sx={{
                    fontSize: "6.25rem",

                    fontWeight: "bold",
                  }}
                >
                  {score.toFixed(1)}
                </Typography>

                <Typography
                  color={getSubTextColor(score)}
                  sx={{ fontSize: "1.875rem", marginTop: "-40px" }}
                >
                  Out of 10
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ScoreModal;
