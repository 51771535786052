import { Typography, Box, SvgIcon, Grid, Switch, styled, Divider, CircularProgress } from "@mui/material";
import { ReactComponent as Circle } from "../assets/POSITIVE Orb.svg";
import { useUser } from "../helpers/UserContext";
import { headingStyles, textStyles } from "../theme/fontStyle";
import * as d3 from 'd3';
import React, { useEffect, useRef } from "react";
import { primary } from "../theme/colors";
import DurationDropdown from "./DurationDropdown";
import { doLoadDashboardTrendline } from "../helpers/api";
import './trendline.css'

interface DataPoint {
  value: number;
  month?: string;
}



interface LineChartProps {
  engagementData: DataPoint[];
  moodData: DataPoint[];
  collaborationData: DataPoint[];
  productivityData: DataPoint[];
  engagementEnabled: boolean;
  moodEnabled: boolean;
  collaborationEnabled: boolean;
  productivityEnabled: boolean;
  dummyData: boolean
}

const positiveColor = "#6FBD44"; // Greenish
const neutralColor = "#F59E31";  // Orangish
const negativeColor = "#B00020"; // Reddish

const determineColor = (value: number) => {
  if (value < 4) return negativeColor; // Reddish for values < 4
  else if (value <= 7) return neutralColor; // Orangish for values 4-7
  return positiveColor; // Greenish for values > 7
};

const MultiColoredLineChart: React.FC<LineChartProps> = ({
  engagementData,
  moodData,
  collaborationData,
  productivityData,
  engagementEnabled,
  moodEnabled,
  collaborationEnabled,
  productivityEnabled,
  dummyData
}) => {
  const d3Container = useRef<SVGSVGElement | null>(null);




  // Define a function that returns the color or gray based on an enabled flag
  const determineStroke = (title: string, enabled: boolean) => {
    if (enabled) {
      return `url(#${title})`; // Use the id of the gradient for this dataset
    } else {
      if (title === "engagement-gradient") return "#D1D1D1";
      else if (title === "mood-gradient") return "#E6E6E6";
      else if (title === "collaboration-gradient") return "#F4F4F4";
      else if (title === "productivity-gradient") return "#F2F2F2";
      return '#E6E6E6'; // Use gray if the dataset is not enabled
    }
  };


  const initializeGradient = (
    g: d3.Selection<SVGGElement, unknown, null, undefined>,
    svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
    line: d3.Line<DataPoint>,
    id: string,
    yScales: [number, number],
    valueDomain: [number, number],
    valueScale: d3.ScaleLinear<number, number, never>,
    data: DataPoint[],
    enabled: boolean) => {
    const gradient = svg.append("defs")
      .append("linearGradient")
      .attr("id", id)
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", yScales[0])
      .attr("x2", 0)
      .attr("y2", yScales[1]);

    // Define the gradient colors based on thresholds
    gradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", determineColor(valueDomain[0])); // Color at the minimum value

    gradient.append("stop")
      .attr("offset", `${valueScale(4) * 100}%`)
      .attr("stop-color", determineColor(4)); // Color at the first threshold

    gradient.append("stop")
      .attr("offset", `${valueScale(7) * 100}%`)
      .attr("stop-color", determineColor(7)); // Color at the second threshold

    gradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", determineColor(valueDomain[1])); // Color at the maximum value


    g.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", determineStroke(id, enabled))
      // .attr("stroke", "url(#line-gradient)")
      .attr("stroke-width", 8)
      .attr("d", line);


    return gradient



  }

  const getLastLineToDraw = (engagementEnabled: boolean, moodEnabled: boolean, collaborationEnabled: boolean, productivityEnabled: boolean) => {

    if (engagementEnabled) {
      return {
        id: 'engagement-gradient',
        data: engagementData,
      }
    } else if (moodEnabled) {
      return {
        id: 'mood-gradient',
        data: moodData,
      }
    } else if (collaborationEnabled) {
      return {
        id: 'collaboration-gradient',
        data: collaborationData,
      }
    } else if (productivityEnabled) {
      return {
        id: 'productivity-gradient',
        data: productivityData,
      }
    } else {
      return {
        id: 'engagement-gradient',
        data: engagementData,
      }
    }

  }





  useEffect(() => {
    if (engagementData && moodData && collaborationData && productivityData && d3Container.current) {
      const svg = d3.select(d3Container.current);
      const margin = { top: 20, right: -7, bottom: 5, left: -2 };
      const width = svg.node()?.getBoundingClientRect().width ?? 0 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;
      const xScale = d3.scaleLinear().domain([0, engagementData.length - 1]).range([margin.left, width - margin.right]);
      const yScale = d3.scaleLinear().domain([0, d3.max(engagementData, d => d.value) ?? 0]).range([height - margin.bottom, margin.top]);

      const valueDomain = d3.extent(engagementData, d => d.value) as [number, number];
      const valueScale = d3.scaleLinear().domain(valueDomain).range([0, 1]);

      const line = d3.line<DataPoint>()
        .curve(d3.curveCardinal) // This creates a smooth curve that passes through the control points
        .x((d, i) => xScale(i))
        .y(d => yScale(d.value));

      svg.selectAll("*").remove();
      const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

      let lastLineToDraw = getLastLineToDraw(engagementEnabled, moodEnabled, collaborationEnabled, productivityEnabled)

      initializeGradient(g, svg,
        line,
        "mood-gradient",
        [
          yScale(valueDomain[0]),
          yScale(valueDomain[1])
        ],
        valueDomain, valueScale,
        moodData, moodEnabled);

      initializeGradient(g, svg,
        line,
        "productivity-gradient",
        [
          yScale(valueDomain[0]),
          yScale(valueDomain[1])
        ],
        valueDomain, valueScale,
        productivityData, productivityEnabled);

      initializeGradient(g, svg,
        line,
        "collaboration-gradient",
        [
          yScale(valueDomain[0]),
          yScale(valueDomain[1])
        ],
        valueDomain, valueScale,
        collaborationData, collaborationEnabled);

      initializeGradient(g, svg,
        line,
        "engagement-gradient",
        [
          yScale(valueDomain[0]),
          yScale(valueDomain[1])
        ],
        valueDomain, valueScale,
        engagementData, engagementEnabled);



      initializeGradient(g, svg,
        line,
        lastLineToDraw.id,
        [
          yScale(valueDomain[0]),
          yScale(valueDomain[1])
        ],
        valueDomain, valueScale,
        lastLineToDraw.data, true);




      g.append('line')
        .attr('x1', margin.left)
        .attr('x2', width - margin.right)
        .attr('y1', yScale(5))
        .attr('y2', yScale(5))
        .attr('stroke', '#757575')
        .attr('stroke-width', 5)
        .attr('stroke-dasharray', '15,15');


      g.append("g")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(xScale));

      g.append("g")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(yScale));


      // To remove the axis lines with D3.js
      g.select('.domain').attr('stroke', 'none');

      // To remove the ticks (the small lines indicating the scale on the axis)
      g.selectAll('.tick line').attr('stroke', 'none');

      // To remove the tick labels (the text next to each tick)
      g.selectAll('.tick text').attr('fill', 'none');

      if (dummyData) {

        svg.append("text")
          .attr("x", (width - margin.right) / 2)
          .attr("y", height + 50)
          .attr("text-anchor", "middle")
          .attr("class", "dummy-label")
          .text("DUMMY DATA");
      }

      // // Add the month labels
      // const months = engagementData.map(d => d.month); // This can be string | undefined
      // const uniqueMonths: string[] = [];

      // months.forEach((month) => {
      //   if (month !== undefined && !uniqueMonths.includes(month)) {
      //     uniqueMonths.push(month);
      //   }
      // });


      // // Filter out any undefined values from the months array
      // const cleanMonths: string[] = uniqueMonths.filter((month): month is string => typeof month === 'string');

      // //starting from end skip every 2nd element and remove it
      // if (cleanMonths.length > 8) {
      //   for (let i = cleanMonths.length - 2; i >= 0; i -= 2) {
      //     cleanMonths.splice(i, 1);
      //   }

      // }


      // // Create the scalePoint using the cleaned array.
      // const x = d3.scalePoint()
      //   .domain(cleanMonths)
      //   .range([0, width])
      //   .padding(1);


      // cleanMonths.forEach((month) => {
      //   // Since we've used a cleaned array, we can be sure 'x(month)' is never undefined.
      //   const xValue = x(month)!;
      //   svg.append("text")
      //     .attr("x", xValue)
      //     .attr("y", height + 50)
      //     .attr("text-anchor", "middle")
      //     .attr("class", "month-label")
      //     .text(month);
      // });


    }
  }, [
    engagementData,
    moodData,
    collaborationData,
    productivityData,
    engagementEnabled,
    moodEnabled,
    collaborationEnabled,
    productivityEnabled
  ]); // Make sure data is a dependency of useEffect

  return (
    <svg
      className="d3-component"
      width="100%"
      height={370}
      ref={d3Container}
    />
  );
};


const TrendlinesComponent: React.FC = () => {
  const { authToken } = useUser();
  const [duration, setDuration] = React.useState("3month");
  const [engagementEnabled, setEngagementEnabled] = React.useState(true);
  const [moodEnabled, setMoodEnabled] = React.useState(false);
  const [collaborationEnabled, setCollaborationEnabled] = React.useState(false);
  const [productivityEnabled, setProductivityEnabled] = React.useState(false);
  const [data, setData] = React.useState<any>([])
  const [loading, setLoading] = React.useState(false);
  const [notEnoughData, setNotEnoughData] = React.useState(false);
  const [dummyData, setDummyData] = React.useState(false)
  const filtersArray = [
    {
      name: "Engagement",
      value: "engagement",
    },
    {
      name: "Mood",
      value: "mood",
    },
    {
      name: "Collaboration",
      value: "collaboration",
    },
    {
      name: "Productivity",
      value: "productivity",
    },
  ];
  const StyledSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: primary.logoColor,
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.3)",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.3)",
    },
  }));


  useEffect(() => {
    if (loading) {
      return
    }
    setLoading(true)
    doLoadDashboardTrendline(authToken!, duration, null).then((response) => {
      if (response.error) {
        console.log(response.error);
        setLoading(false)
        setNotEnoughData(true)
      }
      else {
        console.warn('trendline', response.data?.data);
        // setScores(alignment, response.data);
        setData(response.data?.data)
        if (response.data?.dummyData) {
          setDummyData(true)
        }

        if (!response.data?.data || response.data?.data.length === 0) {

          setNotEnoughData(true)
        }
        setLoading(false)
      }
    });
  }, [duration]);


  const toggle = (type: string) => {
    if (type === 'engagement') {
      setEngagementEnabled(true)
      setMoodEnabled(false)
      setCollaborationEnabled(false)
      setProductivityEnabled(false)
    } else if (type === 'mood') {
      setEngagementEnabled(false)
      setMoodEnabled(true)
      setCollaborationEnabled(false)
      setProductivityEnabled(false)
    } else if (type === 'collaboration') {
      setEngagementEnabled(false)
      setMoodEnabled(false)
      setCollaborationEnabled(true)
      setProductivityEnabled(false)
    } else if (type === 'productivity') {
      setEngagementEnabled(false)
      setMoodEnabled(false)
      setCollaborationEnabled(false)
      setProductivityEnabled(true)
    }


  }
  return (
    <Grid
      container
      sx={{
        // marginBottom: { lg: -5, md: -5 },
      }}
    >
      <Grid
        xs={12}
        sm={12}
        lg={12}
        md={12}

        sx={{
          // width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}

      >

        <div style={{
          // padding: 20,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          flexWrap: 'wrap'
        }}>
          <div style={{ padding: 20 }}>
            <Typography style={headingStyles}>Trendlines</Typography>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
              {filtersArray.map((filter, index) => (
                <div>
                  <Grid container marginTop={"5px"}>
                    <Grid item style={{
                      display: 'flex',
                      marginRight: '20px',
                    }}>
                      <Typography
                        fontStyle={"Helvetica"}
                        fontSize={"14px"}
                        style={{ marginTop: "10px", color: primary.logoColor }}
                      >
                        {filter.name}
                      </Typography>
                      <StyledSwitch
                        checked={
                          filter.value === "engagement"
                            ? engagementEnabled
                            : filter.value === "mood"
                              ? moodEnabled
                              : filter.value === "collaboration" ?
                                collaborationEnabled
                                : productivityEnabled
                        }
                        onChange={() =>
                          filter.value === "engagement"
                            ? toggle('engagement')
                            : filter.value === "mood"
                              ? toggle('mood')
                              : filter.value === "collaboration" ?
                                toggle('collaboration')
                                : toggle('productivity')
                        }
                      // style={{ color: primary.logoColor }}
                      />
                    </Grid>

                  </Grid>
                </div>
              ))}
            </div>
          </div>
          <div style={{ padding: 20 }}>
            <DurationDropdown onChange={(duration) => {
              setDuration(duration.target.value)
              console.log(duration)
            }} />
          </div>
        </div>
      </Grid>

      {loading ? (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', margin: 10 }}>

          <CircularProgress color="primary" style={{ paddingTop: 50, paddingBottom: 50 }} />

        </div>
      ) :
        notEnoughData ?
          (

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', margin: 10 }}>
              <img src={require('../assets/trendline.png')} style={{ width: '250px', height: 'auto' }} />

              <Typography style={{ ...headingStyles, fontWeight: 'bold' }} textAlign={'center'}>
                No Trendline Data
              </Typography>
              <Typography style={textStyles} textAlign={'center'} >
                Trend lines will show up after 3 months of use
              </Typography>

              <Typography style={{ ...textStyles, marginTop: '10px', color: primary.logoColor, fontWeight: 'bold' }} textAlign={'center'}>
                You don't have enough data to show trendlines yet.
              </Typography>
            </div>

          ) : (
            <MultiColoredLineChart
              engagementData={data.map((item: any) => ({ value: item.engagement, month: item.month }))}
              engagementEnabled={engagementEnabled}
              moodData={data.map((item: any) => ({ value: item.avg_sentiment, month: item.month }))}
              moodEnabled={moodEnabled}
              collaborationData={data.map((item: any) => ({ value: item.avg_collaboration, month: item.month }))}
              collaborationEnabled={collaborationEnabled}
              productivityData={data.map((item: any) => ({ value: item.avg_productivity, month: item.month }))}
              productivityEnabled={productivityEnabled}
              dummyData={dummyData}
            />
          )}




    </Grid>
  );
};

export default TrendlinesComponent;
