import { Backdrop, CircularProgress, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { doVerifyEmail } from '../helpers/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../helpers/UserContext';

const VerifyEmail: React.FC = () => {
  const open = true;
  const [searchParams] = useSearchParams();

  const { logout } = useUser();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const email = searchParams.get('email');
    const accessToken = searchParams.get('accessToken');
    const token = searchParams.get('token');

    async function verifyEmail() {
      if (email && accessToken && token) {
        try {
          const response = await doVerifyEmail(email, accessToken, token);
          console.log(response);
          if (response.data) {
            // redirect to payment link

            toast.success('Email Verified successfully');
            logout();
            navigate('/login');
          }
          if (response.error) {
            setLoading(false);
            setMessage(response.error || 'Something went wrong');
          }
        } catch (e) {
          console.warn('error', e);
          toast.error((e as Error).message || 'Something went wrong');
        }
      }
    }

    verifyEmail();
  }, [searchParams]);

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      {!loading && (
        <Box
          sx={{
            backgroundColor: '#ffffff',
            padding: 3,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography
            variant='h5'
            gutterBottom
            align='center'
            fontWeight='bold'
          >
            User verification Failed
          </Typography>
          <br />
          <Typography gutterBottom align='center'>
            {message}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default VerifyEmail;
